import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import {useHistory} from "react-router-dom"
import { Icon } from "react-icons-kit";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";
import { useDispatch, useSelector } from "react-redux";
import { getLogin } from "../store/loginReducer";

const Login = () => {
  const account = useSelector((state) => state.loginReducer.passwords);
  const [isOpen, setIsOpen] = useState(false);
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);
  const dispatch = useDispatch();
  const history = useHistory()
  const showPassword = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setIsOpen(true);
    }, 500);
  }, []);
  const authorization = () => {
    dispatch(getLogin());
    for (let i = 0; i < account.length; i++) {
      if (login == account[i].username && password == account[i].password) {
        history.push('/')
        break
      }
      else{
        alert("Неверно указаны данные!")
        break
      }
    }
  };

  return (
    <>
      <div
        style={
          isOpen == false
            ? {
                transition: "opacity 50ms ease",
                opacity: "0",
              }
            : {
                transition: "opacity 50ms ease",
                opacity: "1",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }
        }
      >
        <h1 className="display-6">Авторизация для администраторов</h1>
        <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Логин</Form.Label>
            <Form.Control
              value={login}
              onChange={(e) => setLogin(e.target.value)}
              type="text"
              placeholder="Введите ваш логин"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Пароль</Form.Label>
            <Form.Control
              type={type}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Введите ваш пароль"
            />
            <span
              class="flex justify-around items-center"
              onClick={showPassword}
            >
              <Icon class="absolute mr-10" icon={icon} size={25} />
            </span>
          </Form.Group>
        </Form>
        <Button onClick={authorization}>Авторизоваться</Button>
      </div>
    </>
  );
};

export default Login;

import React, {useEffect, useState} from "react"
import { useSelector } from "react-redux"
import { getResume, showContacts } from "../store/headhunterReducer"
import { Accordion } from "react-bootstrap"

export const ResultItem = ({result, resume, dispatch}) => {
  const [data, setData] = useState({
    id: result.id,
    name: result.client_name,
    email: result.email,
    phone: result.phone,
    response: result.response_text
  })
  const token = useSelector((state) => state.headhunterReducer.access_token)

  let [years, setYears] = useState(0)
  let [months, setMonths] = useState(0)
  
  useEffect(() => {
    if (resume) {
      setYears(Math.floor(resume.total_experience.months / 12))
      setMonths((resume.total_experience.months % 12))
    }
  }, [resume])

  return(
    <Accordion.Item eventKey={data.id} key={result.id}>
      <Accordion.Header onClick={() => dispatch(getResume(data.email, token))} >{data.name}</Accordion.Header>
    <Accordion.Body>
        {resume ? (
          <div className="container">
            <h5>{resume.last_name && resume.last_name} {resume.first_name && resume.first_name}{resume.middle_name ? ` ${resume.middle_name},` : resume.first_name && `, `} {resume.title}</h5>
            <div className="row row-cols-3">
              <div className="col-8">
                <h6>Контакты</h6>
                {resume.can_view_full_info ? (
                  <div>
                    {resume.contact.length ? (
                      resume.contact.map((con) => 
                      <>
                        <p className="m-0">{con.type.name}:</p>
                        <a className="link-secondary m-0" href={con.type.id === 'cell' ? 
                          `tel:+${con.value.country}${con.value.city}${con.value.number}` 
                          : con.type.id === 'email' && `mailto:${con.value}`}
                        >
                          {con.type.id === 'cell' ? con.value.formatted : con.value}
                        </a>
                        <p className="m-0 text-secondary">{con.comment}</p>
                      </>
                      )
                    ) : (
                      <p className="m-0">Контакты отсутствуют</p>
                    )}
                  </div>
                ) : (
                  <button 
                    className="btn btn-success"
                    onClick={() => dispatch(showContacts(resume.actions.get_with_contact.url, token))}
                  >
                    Показать
                  </button>
                )}
                {(resume.hidden_fields.length > 0) && (
                  <p className="m-0">Соискатель скрыл данные:</p>
                )}
              </div>
              <div className="col-4">
                {(resume.photo) && (
                  <img
                    className="img-thumbnail float-start"
                    src={resume.photo.small}
                    alt="Фотография"
                  />
                )}
              </div>
              <div className="col-12 mt-2">
                <p className="m-0">{resume.age ? `${resume.age} 
                    ${(resume.age % 10 === 1 && resume.age !== 11) ? 'год' :
                    ((resume.age % 10 >= 2 && resume.age % 10 <= 4) && !(resume.age >= 12 && resume.age <= 14)) ? 'года' : 'лет'}` : "Возраст не указан"}
                </p>
                <p className="m-0">{resume.salary ? resume.salary && `${resume.salary.amount} ${resume.salary.currency === "RUR" ? 'руб.' : resume.salary.currency}` : "Зарплата не указана"}</p>
                <p className="m-0">{resume.total_experience ? (`Стаж: ${(years > 0) && `${years} ${(years % 10 === 1 && years !== 11) ? 'год' :
                    ((years % 10 >= 2 && years % 10 <= 4) && !(years >= 12 && years <= 14)) ? 'года' : 'лет'}`} 
                    ${months} ${(months === 1) ? 'месяц' : (months >= 2 && months <= 4) ? 'месяца' : 'месяцев'}`) : "Стаж не указан"}</p>
                <p className="m-0">{resume.education ? `Образование: ${resume.education.level.name}`/*, ${resume.education.primary[0].name}`*/ : "Образование не указано"}</p>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <h5>Резюме не найдено в базе Headhunter</h5>
          </div>
        )}
        <div className="container">
          <h5>Результат звонка</h5>
          <p>{data.response}</p>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  )
}
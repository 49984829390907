import { ResumeInfo } from "../Resume/ResumeInfo"
import { ResumeActions } from "../Resume/ResumeActions"
import { Resume_html } from "../Resume/Resume_html"
import { ResumeReport } from "../Resume/ResumeReport"
import { Modal, Button } from "react-bootstrap"
import { useState } from "react"
import { createContact } from "../../store/googleReducer"
import { uploadRecord, finishCall, applyCandidate, recordCompanyInterview, hideResume } from "../../store/storedVacancyReducer"
import { ToggleButton, ToggleButtonGroup } from "react-bootstrap"

export const VacancyResume = ({resume, dispatch, status}) => {
  let [upload, setUpload] = useState(null)
  let [result, setResult] = useState("")
  const [window, setWindow] = useState(false)

  const openWindow = () => setWindow(true)
  const closeWindow = () => setWindow(false)

  const submitHandler = () => {
    dispatch(finishCall(resume.id, result))
    if (upload) {
      const formData = new FormData
      formData.append('file', upload)
      dispatch(uploadRecord(resume.id, formData))
    }
    dispatch(hideResume())
  }

  const names = resume.full_name ? resume.full_name.split(/[ ]/).filter(Boolean) : ["Резюме", "Evernote"]
  const googleContactInfo = {
    family_name: names[0],
    name: names[1],
    middle_name: names[2] ? names[2] : "",
    phone: resume.phone,
    email: resume.email
  }

  const Interview = () => {
    dispatch(applyCandidate(resume.id))
    dispatch(createContact(googleContactInfo))
    dispatch(hideResume())
  }

  return (
    <>
    
    <ResumeInfo resume={resume}/>
    {resume.evernote_html && (
      <>
        <p className="my-1">Кандидат загружен со сторонних источников</p>
        <Button variant="secondary" onClick={openWindow}>Открыть резюме</Button>
        <Modal size="lg" centered scrollable show={window} onHide={closeWindow}>
          <Modal.Header closeButton>
            <Modal.Title>Резюме</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Resume_html html={resume.evernote_html}/>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeWindow}>
              Закрыть
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )}
    <ResumeActions resume={resume} dispatch={dispatch}/>
    {resume.result ? (
      <ResumeReport resume={resume} dispatch={dispatch}/>
    ) : (
      <div className="container px=0">
      <h5 className="mt-1">Отчет</h5>
        <input
          type = "file"
          className="form-control my-1"
          accept="audio/*"
          onChange = {(e) => setUpload(e.target.files[0])}
        />
        <div className="input-group my-3">
          <textarea
            type="text"
            className="form-control"
            placeholder="Отчет"
            onChange={(e) => setResult(e.target.value)}
          />
          <button
            className="btn btn-outline-secondary"
            onClick={() => submitHandler()}
          >
          Завершить
          </button>
        </div>
      </div>
    )}
    </>
  )
} 
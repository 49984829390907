import React, {useEffect, useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import { СallListItem } from "./СallListItem"
import { getCalls } from "../store/callListReducer";
import { getCampaigns } from "../store/campaignListReducer";
import { Accordion } from "react-bootstrap";

export const CallList = () => {
  const campaigns = [
    {name:'Nmae',
      id:'0',
      }
  ]
  const dispatch = useDispatch()
  const call = useSelector((state) => state.callListReducer)
  // const campaigns = useSelector((state) => state.campaignListReducer)
  const resume = useSelector((state) => state.headhunterReducer.resume)
  const [campaignID, setCampaignID] = useState('0')
  const [loading, setLoading] = useState(false)
  
  
  useEffect(() => {
    dispatch(getCampaigns())
  }, [])
  useEffect(() => {
    setLoading(campaigns)
  }, [campaigns])
  useEffect(() => {
    dispatch(getCalls(campaignID))
  }, [campaignID])


  return (
    <>
    {(loading) ? (
      <div className="container-fluid px-0">
      <select className="form-select form-select-lg sticky-top"
        onChange={(e) => setCampaignID(e.target.value)}
        defaultValue=''
      >
        <option selected hidden>Списки соискателей</option>
        {campaigns.length > 0 && (
          campaigns.map((campaign) =>
          <option key={campaign.name.substring(campaign.name.indexOf('#')+1, campaign.name.indexOf(' '))} value={campaign.name.substring(campaign.name.indexOf('#')+1, campaign.name.indexOf(' '))}>{campaign.name}</option>
        ))}
      </select>
      {(call.length) > 0 ? (
      /*<table className="table table-bordered table-striped">
        <thead className="table-primary">
          <tr>
            <th>Соискатель</th>
            <th>Контакты</th>
          </tr>
        </thead>
        <tbody className="table-secondary">
            {call.map((call) =>
            <tr key={call.id}>
              <СallListItem
                call={call}
                dispatch={dispatch}
              />
            </tr>)}   
        </tbody>*/
      <Accordion>
        {call.map((call) =>
            <СallListItem
              key={call.id}
              call={call}
              resume={resume}
              dispatch={dispatch}
            />
        )}
      </Accordion>
      ) : (
      <div className="text-center">
        <strong className="fs-4">Для начала работы выберите список соискателей</strong>
      </div>
      )}
      </div>
    ) : (
      <div className="d-flex justify-content-center m-4">
        <div className="spinner-border text-primary" role="status"/>
      </div>
    )}
    </>
  )
}
import { authorizationAPI } from "../api/api";
const GET_DATA = "GET_DATA"
const initialState = {
  passwords:[]
}

export const loginReducer = (state = initialState, action) => {
  switch(action.type) {
    case GET_DATA:
      return {...state, passwords:action.payload};
    default:
      return state;
  }
}
export const getLogin = () => async(dispatch)=>{
  try{
    const res = await authorizationAPI.getLogin()
    dispatch({type:GET_DATA, payload:res.data})
  }
  catch(error){
    console.log(error)
  }
}
import axios from "axios"
import { getManagers } from "../store/headhunterReducer"
import { getVacancies } from "../store/storedVacancyReducer"

//Базовый URL API HeadHunter
const url = 'https://api.hh.ru'

export const hhReqAPI = {
  findResumeByEmail(email, token) {
    return axios.get(`${url}/resumes?text=${email}&text.logic=all&text.field=everywhere`, 
      {headers: {
        Authorization: `Bearer ${token}`
      }})
  },
  findResumes(query, token) {
    return axios.get(`${url}/resumes?${query}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }})
  },
  showContacts(link, token) {
    return axios.get(`${link}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }})
  },
  downloadResume(link, token) {
    return axios.get(`${link}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }})
  },
  openResume(id, token) {
    return axios.get(`${url}/resumes/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }})
  },
  openVacancy(id, token) {
    return axios.get(`${url}/vacancies/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  },
  getVacancies(id, token) {
    return axios.get(`${url}/employers/4949743/vacancies/active?manager_id=${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  },
  // getVacancies_new(id) {
  //   return axios.get(`${url}?id=${id}`, {
  //   })
  // },
  getManagers(token) {
    return axios.get(`${url}/employers/4949743/managers`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  }
}
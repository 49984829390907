import { useState, useEffect } from "react"
import { url } from "../../api/api"
import { editReport, uploadRecord, getDocuments, uploadDocuments, hideResume } from "../../store/storedVacancyReducer"
import { ToggleButtonGroup, ToggleButton } from "react-bootstrap"
import { useSelector } from "react-redux"

export const ResumeReport = ({resume, dispatch}) => {
  const [result, setResult] = useState(resume.result)
  const [edit, toggleEdit] = useState(false)
  const [upload, setUpload] = useState(null)
  const [docUpload, setDocUpload] = useState(null)
  const [docView, setDocView] = useState(false)
  const docList = useSelector((state) => state.storedVacancyReducer.files)

  useEffect(() => {
    setResult(resume.result)
  }, [resume])

  const finishEdit = () => {
    dispatch(editReport(resume.id, result))
    if (upload) {
      const formData = new FormData
      formData.append('file', upload)
      dispatch(uploadRecord(resume.id, formData))
    }
    toggleEdit(false)
  }

  const toggleDocList = () => {
    if (!docView) {
      dispatch(getDocuments(resume.hh_uid))
      setDocView(true)
    } else setDocView(false)
  }
  const uploadFiles = () => {
    if (docUpload) {
      for (let i = 0; i < docUpload.length; i++) {
      const formData = new FormData
      formData.append('file', docUpload[i])
      dispatch(uploadDocuments(resume.hh_uid, formData))
      }
    }
  }

  return (
    <div className="container px-0">
      <div className="container">
        <h5 className="mt-1">Отчет:</h5>
        {edit ? (
          <>
          {!resume.voice_url && (
            <>
            <h4>Аудиозапись звонка</h4>
            <input
              type = "file"
              className="form-control my-1"
              accept="audio/*"
              onChange = {(e) => setUpload(e.target.files[0])}
            />
            </>
          )}
          <div className="input-group mt-1">
            <textarea
              type="text"
              value={result}
              className="form-control"
              onChange={(e) => setResult(e.target.value)}
            />
            <button 
              className="btn btn-outline-secondary"
              onClick={() => finishEdit()}
            >
              Завершить
            </button>
          </div>
          </>
          ) : (
          <>
          <p className="text-secondary" align="justify">{resume.result}</p>
          <div className="row row-cols-2 mx-auto">
          <div className="d-grid col-6">
            <button className="btn btn-secondary" onClick={() => toggleEdit(true)}>Редактировать</button>
          </div>
          <div className="d-grid col-6">
            <button className="btn btn-secondary" onClick={() => toggleDocList(true)}>Документы</button>
          </div>
          </div>
          </>
        )}
        { docView ? (
          <>
          { docList.length > 0 ? (
            <div className="row row-cols-3 mx-auto mt-1">
              {docList.map((document) => 
                <div className="d-grid col-4">
                  <button
                    className="btn btn-sm btn-outline-secondary"
                    onClick={() => window.open(`${url}/files/download?path=${document.path}`, '_self')}
                  >
                    {document.file}
                  </button>
                </div>
              )}
            </div>
          ) : (
            <h6 className="mt-1">Для данного резюме документы отсутствуют</h6>
          )}
          <h6>Загрузить документы</h6>
          <div className="input-group mt-1">
            <input
              type = "file"
              className="form-control my-1"
              multiple
              onChange = {(e) => setDocUpload(e.target.files)}
            />
            <button
              className="btn btn-lg btn-outline-secondary"
              onClick = {() => uploadFiles()}
            >
              Загрузить
            </button>
          </div>
          </>
        ) : (
          <>
          </>
        )}
        {resume.voice_url && (
          <>
          <h4>Аудиозапись звонка</h4>
          <audio controls src={resume.voice_url} preload="metadata">
            Прослушивание записи недоступно в текущем браузере
          </audio>
          </>
        )}
      </div>
    </div>
  )
}
import { Modal, Button} from "react-bootstrap"
import { useState, useEffect } from "react"
import { VacancyUpload } from "./VacancyUpload"
import { deleteVacancy } from "../../store/storedVacancyReducer"
import {useHistory} from "react-router-dom"
export const VacancyOptions = ({vac, dispatch}) => {
  const [close, setClose] = useState(false)
  const history = useHistory()
  const closeQuery = () => {
    setClose(true)
  }
  const cancelClose = () => setClose(false)
  // Создает запрос на удаление вакансии, затем отправляет на страницу с вакансиями
  const confirmClose = (data) => {
    dispatch(deleteVacancy(data))
    setClose(false)
    history.push('/vacancies')
  }

  return (
    <>
      <div className="containter-fluid px-0">
        <h4 className="p-3 py-2 m-0 border-bottom">Настройки вакансии</h4>
        <VacancyUpload vac={vac}/>
        <Button variant="danger" className="m-3 mt-4" onClick={closeQuery}>Закрыть вакансию</Button>
      </div>
      <Modal show={close} onHide={cancelClose} centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Подтвердите действие</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Вы уверены, что хотите закрыть вакансию?</p>
          <p>После закрытия вакансии, можно будет просматривать резюме и отчеты, но дальнейшее редактирование и сопровождение резюме по ступеням будет недоступно</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={cancelClose}>
            Отмена
          </Button>
          <Button variant="danger" onClick={() => confirmClose(vac)}>
            Закрыть
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
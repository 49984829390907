import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { getHHVacancy } from "../../store/headhunterReducer";
import { Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { sendResumes } from "../../store/resumesReducer";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "../../styles/vacancies.scss";
import "../../styles/text_hover.scss"
import "../../styles/_underline-goes-right.scss"

export const HHVacancy = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const vacancy = useSelector((state) => state.headhunterReducer.vacancyInfo);
  const token = useSelector((state) => state.headhunterReducer.access_token);
  const [currentVac, setCurrentVac] = useState([]);
  const { id } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [skills, setSkills] = useState("");
  const [desc, setDesc] = useState("");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const clearRequest = () => {
    setShow("");
    setName("");
    setNumber("");
    setEmail("");
    setSkills("");
    setDesc("");
  };
  const handleBack = () => {
    history.push('/vacs')
  }
  function applyJob() {
    const data = {
      name: name,
      email: email,
      number: number,
      skills: skills,
      description: desc,
    };
    setShow(false);
    dispatch(sendResumes(data));
    clearRequest();
    console.log(`data: ${data}`);
  }

  useEffect(() => {
    async function fetchCurrentVac() {
      try {
        const vacsResponse = await axios.get(
          `https://api.hh.ru/vacancies/${id}`
        );
        setCurrentVac(vacsResponse.data);
      } catch (err) {
        console.log(err);
      }
    }
    fetchCurrentVac();
    setTimeout(() => {
      setIsOpen(true);
    }, 200);
  }, [])
  return (
    <>
      {/* <p className='back-to_list display-6 text-hover text-hover-underline-goes-right' style={{ cursor: 'pointer' }} onClick={handleBack}>Вернуться к списку вакансий</p> */}
      {Object.entries(currentVac).length > 0 && (
        <div className="ms-2 px-0">
          <div
            className=""
            style={
              isOpen == false
                ? {
                    transition: "max-height 200ms ease, opacity 200ms ease",
                    opacity: "0",
                  }
                : {
                    transition: "max-height 200ms ease, opacity 200ms ease",
                    opacity: "1",
                    display: "flex",
                    flexDirection: "column",
                  }
            }
          >
            <h3 className="mt-4">
              <a
                className="vac__heading text-secondary h1 text-decoration-none vac__name "
                target="_blank"
                rel="noreferrer"
              >
                {currentVac.name}
              </a>
            </h3>
            <h4 className="vac__heading h2">{currentVac.employer.name}</h4>
            <h4 className="vac__heading h5">
              {currentVac.salary
                ? `от ${
                    currentVac.salary.from
                  } ${currentVac.salary.currency.replace("RUR", "Руб.")} ${
                    !currentVac.salary.gross ? " на руки" : " до вычета налогов"
                  }`
                : "ЗП не указана"}
            </h4>
            <h4 className="vac__heading h5">
              {currentVac.area.name}
            </h4>
            <h4 className="vac__heading h5">
              {currentVac.experience.name.replace("Нет опыта", "Опыт работы не требуется")}
            </h4>
          </div>
          <div
            style={
              isOpen == false
                ? {
                    transition: "max-height 200ms ease, opacity 200ms ease",
                    opacity: "0",
                  }
                : {
                    transition: "max-height 1000ms ease, opacity 1000ms ease",
                    opacity: "1",
                    display: "flex",
                    flexDirection: "column",
                  }
            }
            className="px-0"
            dangerouslySetInnerHTML={{ __html: currentVac.description }}
          />
          <Button

            style={
              isOpen == false
                ? {
                    transition: "max-height 200ms ease, opacity 200ms ease",
                    opacity: "0",
                  }
                : {
                    transition: "max-height 1000ms ease, opacity 1000ms ease",
                    opacity: "1",
                    display: "flex",
                    flexDirection: "column",
                  }
            }
            className="vac__btn ms-2 mb-2"
            onClick={handleShow}
          >
            Откликнуться
          </Button>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Оставьте заявку</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Укажите ваше имя</Form.Label>
                  <Form.Control
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    type="text"
                    placeholder="ФИО"
                    autoFocus
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Укажите ваш email</Form.Label>
                  <Form.Control
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    placeholder="name@example.com"
                    autoFocus
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Укажите ваш номер телефона</Form.Label>
                  <Form.Control
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                    type="tel"
                    pattern="^[0-9-+\s()]*$"
                    maxlength="12"
                    placeholder="Номер телефона"
                    autoFocus
                    required
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>
                    Какими профессиональными навыками вы обладаете?
                  </Form.Label>
                  <Form.Control
                    value={skills}
                    onChange={(e) => setSkills(e.target.value)}
                    type="text"
                    placeholder="Ваши профессиональные навыки"
                    autoFocus
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Расскажите немного о вас</Form.Label>
                  <Form.Control
                    as="textarea"
                    value={desc}
                    onChange={(e) => setDesc(e.target.value)}
                    type="text"
                    rows={3}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Закрыть
              </Button>
              <Button variant="primary" onClick={applyJob}>
                Откликнуться
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}

    </>
  );
};

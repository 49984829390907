import React, {useEffect, useState} from "react"
import { useSelector, useDispatch } from "react-redux"
import { Accordion, Modal, Button, ModalBody } from "react-bootstrap"
import { assignResume } from "../store/storedVacancyReducer"
import { ResumeActions } from "./Resume/ResumeActions"
import { ResumeInfo } from "./Resume/ResumeInfo"
import { Resume_html } from "./Resume/Resume_html"

export const SavedResumeItem = ({resume, vacancies, dispatch}) => {
  let [skills, setSkills] = useState([])
  let [vac_id, setVacID] = useState([])
  const [window, setWindow] = useState(false)

  const openWindow = () => setWindow(true)
  const closeWindow = () => setWindow(false)

  useEffect(() => {
    if (resume.skills) {
      setSkills(resume.skills.split(','))
    }
  }, [])

  const years = Math.floor(resume.experience / 12)
  const months = resume.experience % 12

  return (
    <Accordion.Item eventKey={resume.hh_uid} key={resume.hh_uid}>
      <Accordion.Header>
        {resume.full_name ? resume.full_name : resume.email ? resume.email : resume.phone && resume.phone_formatted}
      </Accordion.Header>
      <Accordion.Body>
      <ResumeInfo resume={resume}/>
    {resume.evernote_html && (
      <>
        <p className="my-1">Кандидат загружен со сторонних источников</p>
        <Button variant="secondary" onClick={openWindow}>Открыть резюме</Button>
        <Modal size="lg" centered scrollable show={window} onHide={closeWindow}>
          <Modal.Header closeButton>
            <Modal.Title>Резюме</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Resume_html html={resume.evernote_html}/>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeWindow}>
              Закрыть
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )}
        <ResumeActions resume={resume} dispatch={dispatch}/>
        <div className="container px-2 mx-2">

          <div className="input-group mt-1">
            <select className="custom-select" onChange={(e) => setVacID(e.target.value)}>
              <option value='0' selected disabled>Вакансия</option>
              {vacancies.map(vac => 
                <option value={vac.id}>{vac.title}</option>  
              )}
            </select>
            {vac_id !== 0 && (
            <button className="btn btn-secondary" onClick={() => dispatch(assignResume(vac_id, resume.id))}>
              Назначить
            </button>
            )}
          </div>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  )
}
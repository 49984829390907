import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams, Link } from "react-router-dom" 
import { getAssignedResumes, hideResume, moveResume, showResume, denyResume, delayResume } from "../../store/storedVacancyReducer"
import { VacancyResume } from "./VacancyResume"
import { VacancyOptions } from "./VacancyOptions"
import { VacancyAnalytics } from "./VacancyAlanytics"
import { ButtonGroup, Dropdown, Button, DropdownButton} from "react-bootstrap"

export const Vacancy = () => {
  const dispatch = useDispatch()
  let { vac } = useParams()
  const resumes = useSelector((state) => state.storedVacancyReducer.resumes)
  const resumeInfo = useSelector((state) => state.storedVacancyReducer.resumeInfo)
  const user = useSelector((state) => state.headhunterReducer.userInfo.uid)
  const [unprocessed, setUnprocessed] = useState([])
  const [call, setCall] = useState([])
  const [secondaryCall, setSecondaryCall] = useState([])
  const [interview, setInterview] = useState([])
  const [no_call, setNoCall] = useState([])
  const [applied, setApplied] = useState([])
  const [denied, setDenied] = useState([])
  const [filteredResumes, setFilteredResumes] = useState([])
  const [status, setStatus] = useState(0)
  
  useEffect(() => {
    dispatch(getAssignedResumes(vac))
  }, [status])

  useEffect(() => {
    setUnprocessed(resumes.filter((resume) => resume.status === null))
    setCall(resumes.filter((resume) => resume.status === 0 && resume.interview_stage === 1))
    setSecondaryCall(resumes.filter((resume) => resume.status === 0 && resume.interview_stage === 2))
    setNoCall(resumes.filter((resume) => resume.status === 1))
    setInterview(resumes.filter((resume) => resume.status === 0 && resume.interview_stage === 3))
    setApplied(resumes.filter((resume) => resume.status === 0 && resume.interview_stage === 4))
    setDenied(resumes.filter((resume) => resume.status === 2))
  }, [resumes])

  const moveFromList = (id, stat) => {
    switch(stat) {
      case 1: {setCall(call.concat(filteredResumes.filter((resume) => resume.id === id))); break}
      case 2: {setSecondaryCall(secondaryCall.concat(filteredResumes.filter((resume) => resume.id === id))); break}
      case 3: {setInterview(interview.concat(filteredResumes.filter((resume) => resume.id === id))); break}
      case 4: {setApplied(applied.concat(filteredResumes.filter((resume) => resume.id === id))); break}
      case 5: {setNoCall(no_call.concat(filteredResumes.filter((resume) => resume.id === id))); break}
      case 6: {setDenied(denied.concat(filteredResumes.filter((resume) => resume.id === id))); break}
    }
    setFilteredResumes(filteredResumes.filter((resume) => resume.id !== id))
  }

  const changeStatus = (stat) => {
    setStatus(stat)
    switch(stat) {
      case 0: {setFilteredResumes(unprocessed); break}
      case 1: {setFilteredResumes(call); break}
      case 2: {setFilteredResumes(secondaryCall); break}
      case 3: {setFilteredResumes(interview); break}
      case 4: {setFilteredResumes(applied); break}
      case 5: {setFilteredResumes(no_call); break}
      case 6: {setFilteredResumes(denied); break}
      default: {setFilteredResumes(filteredResumes); break}
    }
  }

  return (
    <>
    <div className="row px-0 mx-0">
      <div className="col-12 col-lg-2 px-0 border border-dark border-2 h-25 vh-lg-100 overflow-auto">
        <div className="list-group">
          <button type="button" className={`list-group-item list-group-item-action ${status === 0 && `active`} px-0 mb-1`} onClick={() => {dispatch(hideResume()); changeStatus(0)}}>Неразобранные ({unprocessed.length})</button>
          <button type="button" className={`list-group-item list-group-item-action ${status === 1 && `active`} px-0 mb-1`} onClick={() => {dispatch(hideResume()); changeStatus(1)}}>Первичный звонок ({call.length})</button>
          <button type="button" className={`list-group-item list-group-item-action ${status === 2 && `active`} px-0 mb-1`} onClick={() => {dispatch(hideResume()); changeStatus(2)}}>Вторичный звонок ({secondaryCall.length})</button>
          <button type="button" className={`list-group-item list-group-item-action ${status === 3 && `active`} px-0 mb-1`} onClick={() => {dispatch(hideResume()); changeStatus(3)}}>Собеседование ({interview.length})</button>
          <button type="button" className={`list-group-item list-group-item-action ${status === 4 && `active`} px-0 mb-1`} onClick={() => {dispatch(hideResume()); changeStatus(4)}}>Выход на работу ({applied.length})</button>
          <button type="button" className={`list-group-item list-group-item-action ${status === 5 && `active`} px-0 mb-1`} onClick={() => {dispatch(hideResume()); changeStatus(5)}}>Не отвечают ({no_call.length})</button>
          <button type="button" className={`list-group-item list-group-item-action ${status === 6 && `active`} px-0 mb-2`} onClick={() => {dispatch(hideResume()); changeStatus(6)}}>Отказ ({denied.length})</button>
          <button type="button" className={`list-group-item list-group-item-action ${status === 7 && `active`} px-0 mb-0`} onClick={() => {dispatch(hideResume()); setStatus(7)}}>Аналитика</button>
          <button type="button" className={`list-group-item list-group-item-action ${status === 8 && `active`} px-0 mb-0`} onClick={() => {dispatch(hideResume()); setStatus(8)}}>Настройки вакансии</button>
        </div>
      </div>
      {status === 7 ? (
        <div className="col-12 col-lg-10 px-0 border border-dark border-2 vh-75 vh-lg-100 overflow-auto">
          <VacancyAnalytics vac={vac} dispatch={dispatch}/>
        </div>
      ) : status === 8 ? (
        <div className="col-12 col-lg-10 px-0 border border-dark border-2 vh-75 vh-lg-100 overflow-auto">
          <VacancyOptions vac={vac} dispatch={dispatch}/>
        </div>
      ) : (
        <div className="col-12 col-lg-10 px-0 vh-75 vh-lg-100">
          <div className="row mx-0">
            <div className="col-4 px-0 border border-dark border-2 vh-100 overflow-auto">
              {filteredResumes.length > 0 && (
                <div className="list-group">
                  {filteredResumes.map((resume) => 
                    <button type="button" className={`list-group-item list-group-item-action ${resumeInfo.hh_uid === resume.hh_uid && `active`}`} onClick={() => dispatch(showResume(resume.id))}>
                      {resume.full_name ? resume.full_name : resume.email ? resume.email : resume.phone && resume.phone_formatted}
                    </button>
                  )}
                </div>
              )}
            </div>            
            <div className="col-8 border border-dark border-2 px-0 vh-100 overflow-auto">
            {resumeInfo.hh_uid && (
            <div className="row px-0 mx-auto">
            <div className="col-12 px-0 justify-content-start">
              <Dropdown as={ButtonGroup} className="m-2">
                {status <= 3 ? (
                <>
                <Button variant="outline-secondary" onClick={() => {dispatch(moveResume(resumeInfo.id, status + 1, user)); dispatch(hideResume()); moveFromList(resumeInfo.id, status + 1)}}>
                  {
                    status === 0 ? 'Первичный звонок' : 
                    status === 1 ? 'Вторичный звонок' :
                    status === 2 ? 'Собеседование' :
                    status === 3 && 'Прием на работу'
                  }
                </Button>
                <Dropdown.Toggle split variant="outline-secondary" id="stage-dropdown"/>
                </>
                ) : (
                <Dropdown.Toggle variant="outline-secondary">Переместить</Dropdown.Toggle>
                )}
                <Dropdown.Menu>
                  {status !== 0 && <Dropdown.Item onClick={() => {dispatch(moveResume(resumeInfo.id, 1, user)); moveFromList(resumeInfo.id, 1); setStatus(1)}}>Первичный звонок</Dropdown.Item>}
                  {status !== 1 && <Dropdown.Item onClick={() => {dispatch(moveResume(resumeInfo.id, 2, user)); moveFromList(resumeInfo.id, 2); setStatus(2)}}>Вторичный звонок</Dropdown.Item>}
                  {status !== 2 && <Dropdown.Item onClick={() => {dispatch(moveResume(resumeInfo.id, 3, user)); moveFromList(resumeInfo.id, 3); setStatus(3)}}>Собеседование</Dropdown.Item>}
                  {status !== 3 && <Dropdown.Item onClick={() => {dispatch(moveResume(resumeInfo.id, 4, user)); moveFromList(resumeInfo.id, 4); setStatus(4)}}>Прием на работу</Dropdown.Item>}
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown as={ButtonGroup} className="m-2">
                <Dropdown.Toggle variant="outline-secondary" id="delay-dropdown">
                  Нет ответа
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => {dispatch(delayResume(resumeInfo.id, user)); moveFromList(resumeInfo.id, 5); setStatus(5)}}>Не берет трубку</Dropdown.Item>
                  <Dropdown.Item onClick={() => {dispatch(moveResume(resumeInfo.id, 1, user)); moveFromList(resumeInfo.id, 1); setStatus(1)}}>Перезвонить</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown as={ButtonGroup} className="m-2">
                <Dropdown.Toggle variant="outline-secondary" id="deny-dropdown">
                  Отказ
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => {dispatch(denyResume(resumeInfo.id, false, user)); moveFromList(resumeInfo.id, 6); setStatus(6)}}>Отказ кандидата</Dropdown.Item>
                  <Dropdown.Item onClick={() => {dispatch(denyResume(resumeInfo.id, true, user)); moveFromList(resumeInfo.id, 6); setStatus(6)}}>Отказ заказчика</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            </div>
            )}
              <div className="container-fluid px-0">
                {resumeInfo.hh_uid && (
                  <VacancyResume resume={resumeInfo} dispatch={dispatch} status={status}/>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
    </>
  )
}
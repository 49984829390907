import React, {useEffect} from "react"
import { useDispatch, useSelector } from "react-redux"
import { getArchivedResumes } from "../../store/storedVacancyReducer"
import { FavouriteItem } from "../Favourites/FavouriteItem"
import { Accordion } from "react-bootstrap"

export const Archive = () => {
  const dispatch = useDispatch()
  const resumes = useSelector((state) => state.storedVacancyReducer.resumes)

  useEffect(() => {
    dispatch(getArchivedResumes())
  }, [])

  return (
    <div className="container-fluid px-0">
      {resumes.length > 0 && (
        <Accordion>
        {resumes.map((resume) => 
          <FavouriteItem
            key={resume.hh_uid}
            resume={resume}
          />
        )}
        </Accordion>
      )}
    </div>
  )
}
import React, {useEffect, useState} from "react"
import { useSelector, useDispatch } from "react-redux"
import { ResultItem } from "./ResultItem"
import { getResults } from "../store/resultsReducer"
import { getCampaigns } from "../store/campaignListReducer"
import { Accordion } from "react-bootstrap"
import { Link } from "react-router-dom"

export const Results = () => {
  const dispatch = useDispatch()
  const campaigns = useSelector((state) => state.campaignListReducer)
  const results = useSelector((state) => state.resultsReducer)
  const resume = useSelector((state) => state.headhunterReducer.resume)
  const [campaignID, setCampaignID] = useState('0')
  
  useEffect(() => {
    dispatch(getCampaigns())
  }, [])
  useEffect(() => {
    dispatch(getResults(campaignID))
  }, [campaignID])

  return (
    <>
      <div className="container-fluid px-0">
        <select className="form-select form-select-lg sticky-top"
          onChange={(e) => setCampaignID(e.target.value)}
          defaultValue=''
        >
          <option selected hidden>Выберите список</option>
          {campaigns.length > 0 && (
          campaigns.map((campaign) =>
          <option key={campaign.name.substring(campaign.name.indexOf('#')+1, campaign.name.indexOf(' '))} value={campaign.name.substring(campaign.name.indexOf('#')+1, campaign.name.indexOf(' '))}>{campaign.name}</option>
          ))}
        </select>
        {results.length > 0 ? (
          <Accordion>
            {results.map((result) => 
              <ResultItem
                key={result.id}
                result={result}
                resume={resume}
                dispatch={dispatch}
              />
            )}
          </Accordion>
        ) : (
        <div className="text-center">
          <strong className="fs-4">Результаты отсутствуют. Возможно, звонков в данной категории не совершалось.</strong>
        </div>
        )}
      </div>
    </>
  )
}
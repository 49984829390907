import { campaignAPI } from "../api/api";

const GET_CALLS = "GET_CALLS";
const FINISH_CALL = "FINISH_CALL";

export const callListReducer = (state = [], action) => {
  switch(action.type) {
    case GET_CALLS:
      return action.payload;
    case FINISH_CALL:
      return state.filter((call) => call.id !== action.payload)
    default:
      return state;
  }
};

export const getCalls = (id) => async(dispatch) => {
  try {
    const {data} = await campaignAPI.getCallList(id)
    dispatch({type: GET_CALLS, payload: data});
  } catch (error) {
    console.log(error.message);
  }
}

export const finishCall = (id) => async(dispatch) => {
  try {
    await campaignAPI.finishCall(id)
    dispatch({type: FINISH_CALL, payload: id})
  } catch (error) {
    console.log(error.message)
  }
}
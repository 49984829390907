import React, {useEffect, useState} from "react"
import { useSelector, useDispatch } from "react-redux"
import { toggleFavourite } from "../../store/storedVacancyReducer"
import { Accordion } from "react-bootstrap"
import { ResumeInfo } from "../Resume/ResumeInfo"
import { ResumeActions } from "../Resume/ResumeActions"
import { Resume_html } from "../Resume/Resume_html"

export const FavouriteItem = ({resume}) => {
  const dispatch = useDispatch()
  let [skills, setSkills] = useState([])
  const [favourite, setFavourite] = useState(resume.favourite)

  useEffect(() => {
    if (resume.skills) {
      setSkills(resume.skills.split(','))
    }
  }, [])

  const years = Math.floor(resume.experience / 12)
  const months = resume.experience % 12

  return (
    <Accordion.Item eventKey={resume.hh_uid} key={resume.hh_uid}>
      <Accordion.Header>
        {resume.full_name ? resume.full_name : resume.title}
      </Accordion.Header>
      <Accordion.Body>  
        {resume.evernote_html ? (
          <Resume_html html={resume.evernote_html}/>
          ) : (
          <ResumeInfo resume={resume}/>
          )}
        <ResumeActions resume={resume} dispatch={dispatch}/>
      </Accordion.Body>
    </Accordion.Item>
  )
}
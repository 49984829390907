import { url } from "../../api/api"
import { useState } from "react"
import { toggleArchive, toggleFavourite } from "../../store/storedVacancyReducer"
import { useEffect } from "react"
import { createContact } from "../../store/googleReducer"
        
export const ResumeActions = ({resume, dispatch}) => {
  const [favourite, setFavourite] = useState(resume.favourite)
  const [archived, setArchived] = useState(resume.archived)

  useEffect(() => {
    setFavourite(resume.favourite)
    setArchived(resume.archived)
  }, [resume])

  const favToggle = () => {
    dispatch(toggleFavourite(resume.hh_uid, resume.favourite))
    setFavourite(!favourite)
  }  
  const archiveToggle = () => {
    dispatch(toggleArchive(resume.hh_uid, resume.archived))
    setArchived(!archived)
  }

  const contactHandler = () => {
    const data = {
      name: resume.full_name ? resume.full_name : resume.title ? resume.title : `Кандидат evernote (id ${resume.id})`,
      family_name: null,
      middle_name: null,
      phone: resume.phone,
      email: resume.email
    }
    dispatch(createContact(data))
    window.open(`tel:$+${resume.phone}`, '_self')
  }

  return (
    <div className="container px-0">
    <div className="row mt-1 row-cols-6 mx-auto">
      <div className="d-grid col-6">
        <button className="btn btn-secondary" onClick={() => contactHandler()}>Звонок</button>
      </div>
      <div className="d-grid col-6">
        <a href={`https://hh.ru/resume/${resume.hh_uid}`} className="btn btn-secondary" role="button" target="_blank" rel="noorigin noreferrer">Резюме в HH</a>
      </div>
      <div className="d-grid col-6">
        <button className="btn btn-primary" onClick={() => window.open(`mailto:${resume.email}`, '_self')}>E-Mail</button>
      </div>
      <div className="d-grid col-6">
        <a href={`https://wa.me/${resume.phone}`} className="btn btn-success" role="button" target="_blank" rel="noorigin noreferrer">WhatsApp</a>
      </div>
      <div className="d-grid col-6">
        {favourite ? (
          <button className="btn btn-warning" onClick={() => favToggle()}>Добавлено в Избранное</button>
            ) : (
          <button className="btn btn-outline-warning" onClick={() => favToggle()}>Добавить в Избранное</button>
        )}
      </div>
      <div className="d-grid col-6">
        {archived ? (
          <button className="btn btn-danger" onClick={() => archiveToggle()}>Резюме в архиве</button>
        ) : (
          <button className="btn btn-outline-danger" onClick={() => archiveToggle()}>Добавить в архив</button>
        )}
      </div>
      <div className="d-grid col-12">
        <button disabled={resume.evernote_html} className="btn btn-secondary" onClick={() => window.open(`${url}/files/resume?id=${resume.hh_uid}&assignment=${resume.id}`, '_self')}>Печать резюме (Teradata)</button>
      </div>
    </div>
    </div>
  )
}
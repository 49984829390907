import { hhAuthAPI } from "../api/api";
import { hhReqAPI } from "../api/hh_api";
import { resumesAPI } from "../api/api";

const AUTH_HH = "AUTH_HH"
const AUTH_USER = "AUTH_USER"
const GET_USERS = "GET_USERS"
const GET_RESUME = "GET_RESUME"
const NO_RESUME = "NO_RESUME"
const SET_DL_LINK= "SET_DL_LINK"
const SET_LIST= "SET_LIST"
const SET_PAGE="SET_PAGE"
const SET_PAGES="SET_PAGES"
const GET_HH_VACANCIES="GET_HH_VACANCIES"
const GET_HH_VACANCY="GET_HH_VACANCY"
const GET_MANAGERS="GET_MANAGERS"
const CLEAR_VACANCIES="CLEAR_VACANCIES"
const GET_HH_VACANCIES_NEW ="GET_HH_VACANCIES_NEW"

const initialState = {
  isAuth: false,
  userInfo: {},
  userList: [],
  managers: [],
  vacancyList: [],
  vacancyInfo: {},
  resume: null,
  dl_link: "",
  access_token: "",
  show: false,
  upload_list: [],
  upload_page: 1,
  upload_pages: 0
}

export const headhunterReducer = (state = initialState, action) => {
  switch(action.type) {
    case AUTH_HH:
      return {...state, isAuth: true, access_token: action.payload}
    case AUTH_USER:
      return {...state, userInfo: action.payload}
    case GET_MANAGERS:
      return {...state, managers: action.payload}
    case GET_HH_VACANCIES:
      return {...state, vacancyList: state.vacancyList.concat(action.payload)}
    case GET_HH_VACANCIES_NEW:
      return {...state, vacancyList:state.vacancyList.concat(action.payload)}
    case CLEAR_VACANCIES:
      return {...state, vacancyList: []}
    case GET_HH_VACANCY:
      return {...state, vacancyInfo: action.payload}
    case GET_USERS:
      return {...state, userList: action.payload}
    case GET_RESUME:
      return {...state, resume: action.payload}
    case NO_RESUME:
      return {...state, resume: null, dl_link: ""}
    case SET_DL_LINK:
      return {...state, dl_link: action.payload}
    case SET_LIST:
      return {...state, upload_list: action.payload}
    case SET_PAGE:
      return {...state, upload_page: action.payload}
    case SET_PAGES:
      return {...state, upload_pages: action.payload}
    default:
      return state
  }
}

export const loginHH = (code) => async(dispatch) => {
  try {
    let res = await hhAuthAPI.loginHH(code)
    localStorage.setItem("RadiusUserID", res.data.uid)
    dispatch({type: AUTH_HH, payload: res.data.access_token})
    dispatch({type: AUTH_USER, payload: res.data})
    console.log(res.data)
  } catch (error) {
    console.log(error.message)
  }
}

export const authHH = () => async(dispatch) => {
  try {
    let id = localStorage.getItem("RadiusUserID")
    let res = await hhAuthAPI.authHH(id)
    console.log(res)
    dispatch({type: AUTH_HH, payload: res.data.access_token})
    dispatch({type: AUTH_USER, payload: res.data})
  } catch (error) {
    console.log(error.message)
  }
}

export const getUsers = () => async(dispatch) => {
  try {
    let res = await hhAuthAPI.getUsers()
    dispatch({type: GET_USERS, payload: res.data})
  } catch (error) {
    console.log(error.message)
  }
}

export const getResume = (email, token) => async(dispatch) => {
  try {
    let token = localStorage.getItem("hhAccessToken")
    let res = await hhReqAPI.findResumeByEmail(email, token)
    console.log(res.data)
    if (res.data.found > 0) {
      res = await hhReqAPI.openResume(res.data.items[0].id, token)
      console.log(res.data)
      dispatch({type: GET_RESUME, payload: res.data})
      dispatch({type: SET_DL_LINK, payload: res.data.actions.download.rtf.url})
    } else {
      dispatch({type: NO_RESUME})
    }
  } catch (error) {
    console.log(error.message)
  }
}

export const getResumeByID = (id) => async(dispatch) => {
  try {
    let token = localStorage.getItem("hhAccessToken")
    let res = await hhReqAPI.openResume(id, token)
    console.log(res.data)
    dispatch({type: GET_RESUME, payload: res.data})
  } catch (error) {
    console.log(error.message)
  }
}

export const downloadResume = (link, token) => async(dispatch) => {
  try {
    let res = await hhReqAPI.downloadResume(link, token)
    console.log(res)
    let dl = document.createElement('a');
    dl.setAttribute("href", "data:application," + res.data);
    dl.setAttribute("download", "Резюме.rtf");
    dl.click();
  } catch (error) {
    console.log(error.message)
  }
}

export const showContacts = (link) => async(dispatch) => {
  try {
    let token = localStorage.getItem("hhAccessToken")
    let res = await hhReqAPI.showContacts(link, token)
    console.log(res.data)
    dispatch({type: GET_RESUME, payload: res.data})
    dispatch({type: SET_DL_LINK, payload: res.data.actions.download.rtf.url})
  } catch (error) {
    console.log(error.message)
  }
}

export const formResumeList = (query) => async(dispatch) => {
  try {
    let token = localStorage.getItem("hhAccessToken")
    let resumes = await hhReqAPI.findResumes(query, token)
    console.log(resumes.data)
    dispatch({type: SET_LIST, payload: resumes.data.items})
    dispatch({type: SET_PAGE, payload: (resumes.data.page + 1)})
    dispatch({type: SET_PAGES, payload: (resumes.data.pages)})
  } catch (error) {
    console.log(error.message)
  }
}

export const saveResume = (data) => async(dispatch) => {
  try {
    let res = await resumesAPI.addResumeData(data)
    console.log(`${res.data.id} загружено в people_hh`)
  } catch (error) {
    console.log(error.message)
  }
}

export const getManagers = (token) => async(dispatch) => {
  try {
    let res = await hhReqAPI.getManagers(token)
    dispatch({type: GET_MANAGERS, payload: res.data.items})
  } catch (error) {
    console.log(error.message)
  }
}

export const getHHVacancies = (id, token) => async(dispatch) => {
  try { 
    let res = await hhReqAPI.getVacancies(id, token)
    dispatch({type: GET_HH_VACANCIES, payload: res.data.items})
  } catch (error) {
    console.log(error.message)
  }
}
export const getHHVacancies_new = (id) => async(dispatch) => {
  try { 
    let res = await hhReqAPI.getVacancies_new(id)
    dispatch({type: GET_HH_VACANCIES_NEW, payload: res.data.items})
    console.log(res.payload.data.items)
  } catch (error) {
    console.log(error.message)
  }
}

export const clearVacancies = () => async(dispatch) => {dispatch({type: CLEAR_VACANCIES})}

export const getHHVacancy = (id, token) => async(dispatch) => {
  try {
    let res = await hhReqAPI.openVacancy(id, token)
    console.log(res.data)
    dispatch({type: GET_HH_VACANCY, payload: res.data})
  } catch (error) {
    console.log(error.message)
  }
}
import { campaignAPI } from "../api/api";

const GET_CAMPAIGNS = "GET_CAMPAIGNS";

export const campaignListReducer = (state = [], action) => {
  switch(action.type) {
    case GET_CAMPAIGNS:
      return action.payload;
    default:
      return state;
  }
}

export const getCampaigns = () => async(dispatch) => {
  try {
    const {data} = await campaignAPI.getCampaigns();
    dispatch({type: GET_CAMPAIGNS, payload: data})
  } catch (error) {
    console.log(error.message);
  }
}
import React, {useEffect, useState} from 'react'
import {useSelector, useDispatch} from "react-redux"
import {getResumes} from "../../store/resumesReducer"
import {Link} from "react-router-dom"
export const ResumesList = () => {
    const [value, setValue] = useState('')
    const [isRender, setIsRender] = useState(false)
    const [skills, setSkills] = useState([])
    const resumes = useSelector((state) => state.resumesReducer.resumes)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getResumes())
    }, [])
    const filterSkills = () => {
        const array = value.split(',')
        setSkills(array)
        // console.log(skills)
        setIsRender(true)
        const a = ['Обучаемость, Ключевые навыки']
        console.log(a)
        console.log(resumes[0].skills)
        if(resumes[0].skills.includes(a)){
            console.log('yes')
        }
        else{
            console.log('no')
        }
    }
    return (
    <div>
        {/* <div className="container-fluid px-0">
            <input type="text" className="form-control" placeholder="Введите ключевые навыки" />
        </div> */}
        {/* {resumes.map((resume) => (
            <Link to={`/resume/${resume.id}`} className="list-group-item list-group-item-action">
                <div className="d-flex w-100 justify-content-between">
                    <h5 className="mb-1">{resume.full_fio}</h5>
                </div>
                <div className="d-flex justify-contend-end">
                    <small>Ключевые навыки: <strong>{resume.skills}</strong></small> 
                </div>
                <div className="d-flex justify-contend-end">
                    <small>Образование: {resume.education}</small> 
                </div>  
                <div className="d-flex justify-contend-end">
                    <small>Гражданство: {resume.citizenship}</small> 
                </div>
            </Link>
))} */}
 <div>
      <input 
        type="text"
        value={value} 
        onChange={e => setValue(e.target.value)} 
      />
    <button onClick={filterSkills}>Поиск</button>
      {isRender ? resumes
        .filter(item => {
          if (item.skills.split(',').includes(skills)) {
            return true
          }
        })
        .map((resume) => (
            <Link to={`/resume/${resume.id}`} className="list-group-item list-group-item-action">
                <div className="d-flex w-100 justify-content-between">
                    <h5 className="mb-1">{resume.fio}</h5>
                </div>
                <div className="d-flex justify-contend-end">
                    <small>Ключевые навыки: <strong>{resume.skills}</strong></small> 
                </div>
                <div className="d-flex justify-contend-end">
                    <small>Образование: {resume.education}</small> 
                </div>  
                <div className="d-flex justify-contend-end">
                    <small>Гражданство: {resume.citizenship}</small> 
                </div>
            </Link>
))
      : null }
    </div>    </div>
  )
}


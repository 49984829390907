import { Modal, Button, Form, Dropdown, DropdownButton } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import CSVReader from 'react-csv-reader'
import { uploadResumes } from '../../store/storedVacancyReducer'
import * as md5 from 'md5'

export const VacancyUpload = ({vac}) => {
  const dispatch = useDispatch()
  const [show, setShow] = useState(false)
  const [entries, setEntries] = useState([])
  //const [delimiter, setDelimiter] = useState(';')
  const [hasHeader, setHasHeader] = useState(false)
  const [indexes, setIndexes] = useState([])

  const startUpload = () => setShow(true)
  const cancelUpload = () => {
    setIndexes([])
    setEntries([])
    setShow(false)
  }
  const finishUpload = () => {
    const entrydata = hasHeader ? entries.slice(1) : entries 
    const data = {
      items: entrydata.map((entry) => {
      return {
        uid: md5(entry),
        name: indexes.indexOf('name') !== -1 ? entry[indexes.indexOf('name')] : null,
        title: indexes.indexOf('title') !== -1 ? entry[indexes.indexOf('title')] : null,
        phone: indexes.indexOf('phone') !== -1 ? entry[indexes.indexOf('phone')] : null,
        email: indexes.indexOf('email') !== -1 ? entry[indexes.indexOf('email')] : null,
        skills: indexes.indexOf('skills') !== -1 ? entry[indexes.indexOf('skills')] : null
      }}),
      vacancy: vac
    }
    //console.log(data)
    dispatch(uploadResumes(data))
    setIndexes([])
    setEntries([])
    setShow(false)
  }
  
  useEffect(() => {
    if (entries.length > 0)
      setIndexes(entries[0].map(() => ""))
  }, [entries])
  
  const updateIndex = (index, value) => {
    let newIndexes = indexes.map((val, indx) => {
      if (indx === index)
        return value
      else
        return val
    })
    setIndexes(newIndexes)
  }

  return (
    <>
      <Button variant="success" className="m-3 mt-4" onClick={startUpload}>
        Загрузить новые резюме c .csv
      </Button>
      <Modal show={show} centered scrollable onHide={cancelUpload} backdrop="static" size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Загрузка новых резюме с .csv-файла</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="input-group px-3 pb-2">
            <CSVReader
              cssClass="form-control p-0"
              fileEncoding='cp1251'
              onFileLoaded={(data) => setEntries(data)}
              parserOptions={{skipEmptyLines: false}}
            />
            <div className='input-group-text'>
              <input className='form-check-input mt-0' type="checkbox" id="docheader" checked={hasHeader} onChange={() => setHasHeader(!hasHeader)}/>
              <label fro="docheader" className="ms-1">Пропустить заголовки</label>
            </div>
          </div>
          {(entries.length > 0) && (
            <div className='container'>
              <table className='table-bordered table-hover w-100' style={{minWidth: "600px"}}>
                <thead>
                  <tr>
                    {entries[0].map((v, index) => 
                      <td className='p-1'>
                        <Form.Select onChange={(e) => updateIndex(index, e.target.value)}>
                          <option value="">Тип данных</option>
                          <option value="name">ФИО</option>
                          <option value="title">Профессия по резюме</option>
                          <option value="phone">Телефон</option>
                          <option value="email">E-Mail</option>
                          <option value="skills">Ключевые навыки</option>
                        </Form.Select>
                      </td>
                    )}
                  </tr>
                  </thead>
                  <tbody>
                  {(entries.length > 5) ? (entries.slice(0, 5).map((item) => 
                    <tr>
                      {item.map((value) => <td>{value}</td>)}
                    </tr>
                  )
                  ) : (entries.map((item) =>
                    <tr>
                      {item.map((value) => <td>{value}</td>)}
                    </tr>
                  ))}
                  </tbody>
              </table>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={cancelUpload}>
            Отмена
          </Button>
          <Button variant="success" onClick={finishUpload}>
            Загрузить
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

/* <DropdownButton id="dropdown-item-button" variant="secondary" title={`Разделитель ( ${delimiter == '\t' ? 'Таб.' : delimiter} )`}>
              <Dropdown.Item as="button" onClick={() => setDelimiter(";")}> ; (Точка с запятой)</Dropdown.Item>
              <Dropdown.Item as="button" onClick={() => setDelimiter(",")}> , (Запятая)</Dropdown.Item>
              <Dropdown.Item as="button" onClick={() => setDelimiter(".")}> . (Точка)</Dropdown.Item>
              <Dropdown.Item as="button" onClick={() => setDelimiter('\t')}>Табуляция</Dropdown.Item> 
            </DropdownButton> */
import React from 'react'
import axios from 'axios'
// https://sms.ru/my/balance?api_id=[зарегистрируйтесь, чтобы получить api_id]&json=1
export const Mailing = () => {
  const ID = '7C737461-7995-AC3F-0EEA-827F27C51287'
  function sendMail(e) {
    const response = axios.post(`https://sms.ru/my/balance?api_id=[7C737461-7995-AC3F-0EEA-827F27C51287]&json=1&test=1`)
  }
  return (
    <div>
      <button onClick={sendMail}>Кнопка</button>
    </div>
  )
}

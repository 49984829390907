import React, {useEffect, useState} from "react";
import { useSelector } from "react-redux";
import { finishCall } from "../store/callListReducer";
import { createContact } from "../store/googleReducer";
import { getResume, downloadResume, showContacts, saveResume } from "../store/headhunterReducer";
import { Accordion } from "react-bootstrap";

export const СallListItem = ({call, dispatch, resume}) => {
  const [data, setData] = useState({
    id: call.id,
    name: call.client_name,
    email: call.email,
    phone: call.phone,
    result: ""
  });
  const token = useSelector((state) => state.headhunterReducer.access_token)
  const dl_link = useSelector((state) => state.headhunterReducer.dl_link)

  const mail_link = `mailto:${data.email}`
  const phone_link = `tel:+${data.phone}`
  const whatsapp_link = `https://wa.me/${data.phone}`
  
  let [years, setYears] = useState(0)
  let [months, setMonths] = useState(0)
  
  useEffect(() => {
    if (resume) {
      setYears(Math.floor(resume.total_experience.months / 12))
      setMonths((resume.total_experience.months % 12))
    }
  }, [resume])

  const names = data.name.split(/[ ]/).filter(Boolean)
  const googleData = {
    family_name: names[0],
    name: names[1],
    middle_name: names[2] ? names[2] : "",
    phone: data.phone,
    email: data.email
  }
  const googlePeopleSend = async(e) => {
    dispatch(createContact(googleData));
    dispatch(finishCall(data))
  }
  return(
    <Accordion.Item eventKey={data.id} key={call.id}>
      <Accordion.Header onClick={() => dispatch(getResume(data.email, token))} >{data.name}</Accordion.Header>
    <Accordion.Body>
        {resume && (
          <div className="container">
            <h5>{resume.last_name && resume.last_name} {resume.first_name && resume.first_name}{resume.middle_name ? ` ${resume.middle_name},` : resume.first_name && `, `} {resume.title}</h5>
            <div className="row row-cols-3">
              <div className="col-8">
                <h6>Контакты</h6>
                {resume.can_view_full_info ? (
                  <div>
                    {resume.contact.length ? (
                      resume.contact.map((con) => 
                      <>
                        <p className="m-0">{con.type.name}:</p>
                        <a className="link-secondary m-0" href={['cell', 'work', 'home'].includes(con.type.id) ? 
                          `tel:+${con.value.country}${con.value.city}${con.value.number}` 
                          : con.type.id === 'email' && `mailto:${con.value}`}
                        >
                          {['cell', 'work', 'home'].includes(con.type.id) ? con.value.formatted : con.value}
                        </a>
                        <p className="m-0 text-secondary">{con.comment}</p>
                      </>
                      )
                    ) : (
                      <p className="m-0">Контакты отсутствуют</p>
                    )}
                  </div>
                ) : (
                  <button 
                    className="btn btn-success"
                    onClick={() => dispatch(showContacts(resume.actions.get_with_contact.url))}
                  >
                    Показать
                  </button>
                )}
                {(resume.hidden_fields.length > 0) && (
                  <p className="m-0">Соискатель скрыл данные:</p>
                )}
              </div>
              <div className="col-4">
                {(resume.photo) && (
                  <img
                    className="img-thumbnail float-start"
                    src={resume.photo.small}
                    alt="Фотография"
                  />
                )}
              </div>
              <div className="col-12 mt-2">
                <p className="m-0">{resume.age ? `${resume.age} 
                    ${(resume.age % 10 === 1 && resume.age !== 11) ? 'год' :
                    ((resume.age % 10 >= 2 && resume.age % 10 <= 4) && !(resume.age >= 12 && resume.age <= 14)) ? 'года' : 'лет'}` : "Возраст не указан"}
                </p>
                <p className="m-0">{resume.salary ? resume.salary && `${resume.salary.amount} ${resume.salary.currency === "RUR" ? 'руб.' : resume.salary.currency}` : "Зарплата не указана"}</p>
                <p className="m-0">{resume.total_experience ? (`Стаж: ${(years > 0) && `${years} ${(years % 10 === 1 && years !== 11) ? 'год' :
                    ((years % 10 >= 2 && years % 10 <= 4) && !(years >= 12 && years <= 14)) ? 'года' : 'лет'}`} 
                    ${months} ${(months === 1) ? 'месяц' : (months >= 2 && months <= 4) ? 'месяца' : 'месяцев'}`) : "Стаж не указан"}</p>
                <p className="m-0">{resume.education ? `Образование: ${resume.education.level.name}`/*, ${resume.education.primary[0].name}`*/ : "Образование не указано"}</p>
              </div>
            </div>
          </div>
        )}
        <div className = "col-12">
          <h6>Контакты:</h6>
          <p className="m-0">Телефон:</p>
          <a className="link-secondary m-0" href={`tel:${data.phone ? data.phone : ""}`}>
            {data.phone ? data.phone : ""}
          </a>
          <p className="m-0">E-Mail/Ссылка на группу:</p>
          <a className="link-secondary m-0" href={`mailto:${data.email ? data.email : ""}`}>
            {data.email ? data.email : ""}
          </a>
          </div>
        <div className="container">
          <h5>Действия</h5>
          <div className="row row-cols-5 mx-auto">
            <div className="d-grid col-6">
              <a href={phone_link} onClick={() => dispatch(googlePeopleSend())} className="btn btn-primary" role="button">Позвонить</a>
            </div>
            <div className="d-grid col-6">
              {(dl_link.length > 0) && (<button
                className="btn btn-primary"
                onClick={() => dispatch(downloadResume(dl_link, token))}
              >
                Загрузить
              </button>)}
              </div>
            <div className="d-grid col-6">
              <a href={mail_link} className="btn btn-primary" role="button">E-Mail</a>
            </div>
            <div className="d-grid col-6">
              <a href={whatsapp_link} className="btn btn-success" role="button" target="_blank" rel="noorigin noreferrer">WhatsApp</a>
            </div>
          </div>
        </div>
        <div className="container">
          <h5>Результат</h5>
          <div className="input-group mb-3">
          <input
            type="text"
            className="form-control"
            onChange={(e) => setData({...data, result: e.target.value})}
            value={data.result}
          />
          <button
            className="btn btn-outline-secondary"
            onClick={() => googlePeopleSend()}
          >
            Завершить
          </button>
          </div>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  )
}

/* <div className="d-grid col-6">
              <button
                className="btn btn-secondary"
                onClick={() => dispatch(saveResume(resume))}
              >
                Сохранить
              </button>
            </div> */
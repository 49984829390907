import { useEffect, useState } from "react";
import {
  clearVacancies,
  getHHVacancies_new,
  getManagers,
} from "../../store/headhunterReducer";
import { Form, Button, InputGroup, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import ReactPaginate from "react-paginate";
import image from "../../assets/python.png"
export const HHVacancies = ({ searchValue, setSearchValue }) => {
  const inputStyle = {
    display: "flex",
    flexDirection: "column",
  };
  const textStyle = {
    margin: "0",
    padding: "0",
  };
  const flexStyle = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  };
  const centerStyle = {
    display: "flex",
    flexDirection: "column",
  };

  // Кол-во вакансий на страницу
  const ITEMS_PER_PAGE = 20;
  const [isOpen, setIsOpen] = useState(false);
  const [vacs, setVacs] = useState([]);
  const [isRender, setIsRender] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const [itemOffset, setItemOffset] = useState(0);
  const [dataFound, setDataFound] = useState(0);
  const [dataPages, setDataPages] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  // Загрузка вакансий с HeadHunter
  async function fetchVacs() {
    let URL = `https://api.hh.ru/vacancies?date_from=2024-01-19&search_field=name&page=${itemOffset}`;
    if (searchValue.length > 0) {
      URL += `&text=${searchValue}`;
    }
    if (filterValue.length > 0) {
      let array = filterValue.split(",");
      for (let i = 0; i < array.length; i++) {
        URL += `&${array[i]}`;
      }
    }
    URL = URL.toLowerCase();
    URL += `&per_page=${ITEMS_PER_PAGE}`;
    try {
      const vacsResponse = await axios.get(URL);
      setVacs(vacsResponse.data.items);
      setIsRender(true);
      setDataPages(vacsResponse.data.pages);
      setDataFound(vacsResponse.data.found);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setIsOpen(true);
    }, 500);
  }, []);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + ITEMS_PER_PAGE;
    setPageCount(Math.ceil(vacs.length / ITEMS_PER_PAGE));
    fetchVacs();
  }, [itemOffset, ITEMS_PER_PAGE]);

  // Осталось настроить нормально фильтрацию
  // Пагинация работает нормально
  // Динамический поиск отключен
  // Поисковик рабоатет стабильно

  let endOffset = itemOffset + ITEMS_PER_PAGE;

  function handlePageClick(event) {
    const newOffset = (event.selected * ITEMS_PER_PAGE) % dataFound;
    setItemOffset(event.selected);
  }
  const onChangeSearchInput = (event) => {
    setSearchValue(event.target.value);
  };
  const onChangeFilterInput = (event) => {
    setFilterValue(event.target.value);
  };

  let filteredVacs;
  const renderVacs = () => {
    filteredVacs = vacs.filter((item) => item.name.includes(searchValue));
  };

  return (
    <div
      style={
        isOpen == false
          ? {
            transition: "max-height 200ms ease, opacity 200ms ease",
            opacity: "0",
          }
          : {
            transition: "max-height 600ms ease, opacity 600ms ease",
            opacity: "1",
            display: "flex",
            flexDirection: "column",
          }
      }
      className="container-fluid px-0 mx-0"
    >
      <InputGroup className="mb-3">
        <Form.Control
          size='lg'
          onChange={onChangeSearchInput}
          placeholder="Название вакансии"
        />
        <Button variant="outline-success" onClick={fetchVacs} id="button-addon2">
          Поиск вакансий
        </Button>
      </InputGroup>
      <div>
        {vacs.length > 0 &&
          vacs.map((vacancy) => (
            <Link
              key={vacancy.id}
              query={{ id: vacancy.id }}
              to={`/vacs/${vacancy.id}`}
              className="vac__list-group list-group-item  list-group-item-action"
            >
              <div className='vac__list-block'>
                <div className="align-items-center">
                  <div>
                    <div>
                      <h3 className="vac__list-elem mb-1">{vacancy.name}</h3>

                    </div>

                  </div>
                </div>
                <div  style={{ color: 'gray' }}>
                  <h3 className="vac__list-elem-employer h6">{vacancy.employer.name}</h3>
                  <h4 className="vac__list-elem-salary h6" >{vacancy.salary == null ? (
                      <p>ЗП не указана</p>
                    ) : vacancy.salary.from == null ? (
                      <p>
                        До {vacancy.salary.to} {vacancy.salary.currency.replace('RUR', "RUB")}
                      </p>
                    ) : vacancy.salary.to == null ? (
                      <p>
                        От {vacancy.salary.from} {vacancy.salary.currency.replace('RUR', "RUB")}
                      </p>
                    ) : (
                      <p>
                        {vacancy.salary.from}-{vacancy.salary.to}{" "}
                        {vacancy.salary.currency.replace('RUR', "RUB")}
                      </p>
                    )}</h4>
                </div>
              
                </div>
              <hr />
            </Link>
          ))}
      </div>
      {isRender ? (
        <ReactPaginate
          previousLabel="Previous"
          nextLabel="Next"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          pageCount={dataPages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName="pagination"
          activeClassName="active"
          forcePage={itemOffset}
        />
      ) : null}
    </div>
  );
};

//  <div className="d-flex w-100 justify-content-between">
//         <h5 className="mb-1">{vacancy.name}</h5>
//         <p style={textStyle}>{vacancy.employer.name}</p>
//         {/* {vacancy.employer.logo_urls == null ? null : (
//           <img
//             width={60}
//             height={60}
//             src={vacancy.employer.logo_urls.original}
//           ></img>
//         )} */}
//       </div>
//       <div className="d-flex w-100 justify-content-between">
//         <p style={textStyle}>{vacancy.area.name}</p>
//         {vacancy.salary == null ? (
//           <p>ЗП не указана</p>
//         ) : vacancy.salary.from == null ? (
//           <p>
//             До {vacancy.salary.to} {vacancy.salary.currency}
//           </p>
//         ) : vacancy.salary.to == null ? (
//           <p>
//             От {vacancy.salary.from} {vacancy.salary.currency}
//           </p>
//         ) : (
//           <p>
//             {vacancy.salary.from}-{vacancy.salary.to}{" "}
//             {vacancy.salary.currency}
//           </p>
//         )}
//       </div>

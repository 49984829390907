import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CallList } from "./components/CallList";
import "./styles.css";
import Logo from "./RadiusLogo.png";
import { Route, Switch, Link, useLocation } from "react-router-dom";
import { loginGoogle, authorizeGoogle } from "./store/googleReducer";
import { GoogleLogin } from "react-google-login";
import qs from "qs";
import { hhAuthUrl } from "./api/api";
import axios from "axios";
import { loginHH, authHH } from "./store/headhunterReducer";
import { Results } from "./components/Results";
// import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { Vacancy } from "./components/Vacancy/VacancyPage";
import { Search } from "./components/Search";
import { Nav, Navbar, Offcanvas } from "react-bootstrap";
import { Privacy } from "./components/Privacy";
import { Favourites } from "./components/Favourites/Favourites";
import { hhReqAPI } from "./api/hh_api";
import { Archive } from "./components/Archive/Archive";
import { importFile } from "./store/storedVacancyReducer";
import { HHVacancies } from "./components/HHVacancy/HHVacancyList";
import { HHVacancy } from "./components/HHVacancy/HHVacancyPage";
import { VacancyList } from "./components/Vacancy/VacancyList";
import {ResumesList} from "./components/Resumes/ResumesList";
import { Mailing } from "./components/Mailing";
import Login from "./components/Login";
import "animate.css/animate.min.css";
import ScrollAnimation from "react-animate-on-scroll";
export const App = () => {
  const dispatch = useDispatch();
  const googleAuth = useSelector((state) => state.googleReducer.isAuth);
  const hhAuth = useSelector((state) => state.headhunterReducer.isAuth);
  const userInfo = useSelector((state) => state.headhunterReducer.userInfo);
  const googleUser = useSelector((state) => state.googleReducer.user)
  const location = useLocation();
  const [imp, setImport] = useState(null);
  const [searchValue, setSearchValue] = useState("");

  const uploadFiles = () => {
    if (imp) {
      const formData = new FormData();
      formData.append("file", imp[0]);
      dispatch(importFile(formData));
    }
  };

  const testHH = async () => {
    const res = await hhReqAPI.test();
    console.log(res.data);
  };

  let hhAuthCode = qs.parse(location.search, { ignoreQueryPrefix: true }).code;

  useEffect(() => {
    hhAuthCode ? dispatch(loginHH(hhAuthCode)) : dispatch(authHH());
    dispatch(authorizeGoogle());
  }, []);

  /*setInterval(() => {
    let expiry = localStorage.getItem("hhAuthExpiry")
    if (Date.now() > expiry) {
      dispatch(authHH())
    } 
  }, 360000)*/

  const GoogleSuccessHandler = async(GoogleData) => {
    dispatch(loginGoogle(GoogleData.code));  
    console.log(GoogleData.code)
    const { profileObj } = GoogleData;
    console.log(`Welcome, ${profileObj.name}!`);
  };

  return (
    <div className="container-fluid px-0">
     
      <Navbar style={{backgroundColor:'#09b83f'}} expand={false} className="px-3">
        <div className="justify-content-start">
          <Navbar.Toggle />
          <Navbar.Brand>
            <Link to="/">
              <img src={Logo} className="logo" height="70" width="auto" />
            </Link>
          </Navbar.Brand>
        </div>
        <Nav className="justify-content-end">
          {/* {!googleAuth ? (
            <li className="nav-item">
              <GoogleLogin
                clientId="95212025760-m5ojh0j3i636o7ec7pitrrkmng60lc30.apps.googleusercontent.com"
                render={(renderProps) => (
                  <button
                    className="btn btn-primary btn-lg"
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                  >
                    Google
                  </button>
                )}
                buttonText="Вход"
                responseType="code"
                onSuccess={GoogleSuccessHandler}
                cookiePolicy={"single_host_origin"}
                prompt="consent"
              />
            </li>
          ) : <p>Ваша информация</p>}  */}
          <Link to="/login"><button style={{backgroundColor:"#01b875", border:"none"}} className="btn btn-primary">Обратная связь</button></Link>
        </Nav>
        {/* <Nav className="justify-content-end"> */}
          {/* {!hhAuth ? (
            <li>
              <a
                role="button"
                className="btn btn-danger btn-lg"
                href={hhAuthUrl}
              >
                HH
              </a>
            </li>
          ) : (
            <h4>
              {userInfo.last_name} {userInfo.first_name}
            </h4>
          )} */}
        {/* </Nav> */}
        <Navbar.Offcanvas id="mainMenu" placement="start">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Radius-Вакансии</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className="d-flex flex-column h-100">
            <Nav className="justify-content-start flex-grow-1 p-3">
              {/* <Navbar.Toggle className="bg-light mb-2 position-relative">
                <Link
                  to="/"
                  className="text-secondary text-decoration-none p-1 stretched-link"
                >
                  Кандидаты
                </Link>

              </Navbar.Toggle>
              <Navbar.Toggle className="bg-light mb-2 position-relative">
                 <Link
                  to="/resumes"
                  className="text-secondary text-decoration-none p-1 stretched-link"
                >
                  Резюме
                </Link> *
                
              </Navbar.Toggle>
              <Navbar.Toggle className="bg-light mb-2 position-relative">
                <Link
                  to="/vacancies"
                  className="text-secondary text-decoration-none p-1 stretched-link"
                >
                  Вакансии
                </Link>
              </Navbar.Toggle> */}
              <Navbar.Toggle className="bg-light mb-2 position-relative">
                <Link
                  to="/vacs"
                  className="text-secondary text-decoration-none p-1 stretched-link"
                >
                  Вакансии
                </Link>
               </Navbar.Toggle>
             {/* <Navbar.Toggle className="bg-light mb-2 position-relative">
                <Link
                  to="/favourites"
                  className="text-secondary text-decoration-none p-1 stretched-link"
                >
                  Избранные резюме
                </Link>
              </Navbar.Toggle>
              <Navbar.Toggle className="bg-light mb-2 position-relative">
                <Link
                  to="/archive"
                  className="text-secondary text-decoration-none p-1 stretched-link"
                >
                  Архив резюме
                </Link>
              </Navbar.Toggle>
              <Navbar.Toggle className="bg-light mb-2 position-relative">
                <Link
                  to="/call_list"
                  className="text-secondary text-decoration-none p-1 stretched-link"
                >
                  Обзвон соискателей (старое)
                </Link>
              </Navbar.Toggle>
              <Navbar.Toggle className="bg-light mb-2 position-relative">
                <Link
                  to="/results"
                  className="text-secondary text-decoration-none p-1 stretched-link"
                >
                  Отчет (старое)
                </Link>
            </Navbar.Toggle> */}
            </Nav> 
            <footer className="footer mt-auto py-3 bg-light">
              <p className="text-secondary px-1">ООО Радиус</p>
              <Navbar.Toggle>
                <Link to="/privacy" className="link-secondary">
                  Политика конфиденциальности
                </Link>
              </Navbar.Toggle>
            </footer>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Navbar> 
      <Switch>
        {<Route exact path="/" render={() => <Search />} />}
        {<Route exact path="/results" render={() => <Results />} />}
        {<Route exact path="/privacy" render={() => <Privacy />} />}
        {<Route exact path="/call_list" render={() => <CallList />} />}
        {<Route exact path="/favourites" render={() => <Favourites />} />}
        {<Route exact path="/archive" render={() => <Archive />} />}
        {<Route exact path="/vacancies" render={() => <VacancyList />} />}
        {<Route exact path="/vacancy/:vac" render={() => <Vacancy />} />}
        {<Route exact path="/resumes" render={() => <ResumesList />} />}
        {<Route exact path="/mailing" render={() => <Mailing />} />}
        {<Route exact path="/login" render={() => <Login/>}/>}
        {
          <Route
            exact
            path="/vacs"
            render={() => (
              <HHVacancies
                setSearchValue={setSearchValue}
                searchValue={searchValue}
              />
            )}
          />
        }
        {<Route exact path="/vacs/:id" render={() => <HHVacancy />} />}
        {/* {<Route exact path="/hh/vacancies/:id" render={() => <HHVacancy/>} />} */}
      </Switch>

    </div>
  );
};
/* 
        <Nav>
          <div className="input-group">
            <div className="custom-file">
              <input 
                type="file" 
                className="custom-file-input"
                onChange={(e) => setImport(e.target.files)}
              />
            </div>
            <div className="input-group-append">
              <button onClick={() => uploadFiles()}></button>
            </div>
          </div>
        </Nav>
        <li className="nav-item">
            <Link className="nav-link" to="/upload">
              <button className="btn btn-primary btn-lg">
                Резюме
              </button>
            </Link>
          </li>      
          
        
        {<Route exact path="/assignments" render={() => <Assignments/>} />}
        {<Route exact path="/assignment_results" render={() => <AssignmentResults/>}/>}
        {<Route exact path="/company" render={() => <CompanyInterviews/>} />}
        {<Route exact path="/invitations" render={() => <Invitations/>} />}
              <Navbar.Toggle className="bg-light mb-2 position-relative"><Link to="/assignments" className="text-secondary text-decoration-none p-1 stretched-link">Обзвон кандидатов</Link></Navbar.Toggle>
              <Navbar.Toggle className="bg-light mb-2 position-relative"><Link to="/assignment_results" className="text-secondary text-decoration-none p-1 stretched-link">Отчеты по звонкам</Link></Navbar.Toggle>
              <Navbar.Toggle className="bg-light mb-2 position-relative"><Link to="/invitations" className="text-secondary text-decoration-none p-1 stretched-link">Приглашенные соискатели</Link></Navbar.Toggle>
              <Navbar.Toggle className="bg-light mb-2 position-relative"><Link to="/company" className="text-secondary text-decoration-none p-1 stretched-link">Собеседования</Link></Navbar.Toggle>
          
{<Route exact path="/resumes" render={() => <SavedResumes/>} />}*/

import { vacanciesAPI } from "../api/api";

const GET_VACANCIES = "GET_VACANCIES"
const GET_VACANCY_RESUMES = "GET_VACANCY_RESUMES"
const SET_VACANCY_INFO = "SET_VACANCY_INFO"
const ASSIGN_RESUME = "ASSIGN_RESUME"
const CLEAR_FIELDS = "CLEAR_FIELDS"
const UPDATE_VACANCY = "UPDATE_VACANCY"
const TOGGLE_FAVOURITE = "TOGGLE_FAVOURITE"
const ARCHIVE_RESUME = "ARCHIVE_RESUME"
const UPDATE_REPORT = "UPDATE_REPORT"
const GET_FILES = "GET_FILES"
const UPLOAD_FILES = "UPLOAD_FILES"
const SHOW_RESUME = "SHOW_RESUME"
const HIDE_RESUME = "HIDE_RESUME"
const GET_STATISTICS = "GET_STATISTICS"
const NEW_VACANCY = "NEW_VACANCY"
const DELETE_VACANCY = "DELETE_VACANCY"

const initialState = {
  vacancies: [],
  resumes: [],
  resumeInfo: {},
  vacancy_info: null,
  vacancy_stats: [],
  files: []
}

export const storedVacancyReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_VACANCIES:
      return {...state, vacancies: action.payload}
    case NEW_VACANCY:
      return {...state, vacancies: [... state.vacancies, action.payload]}
    case GET_VACANCY_RESUMES: {
      return {...state, resumes: action.payload}
    }
    case SET_VACANCY_INFO: {
      return {...state, vacancy_info: action.payload}
    }
    case SHOW_RESUME: {
      return {...state, resumeInfo: state.resumes.find((resume) => resume.id === action.payload)}
    }
    case HIDE_RESUME: {
      return {...state, resumeInfo: {}}
    }
    case UPDATE_REPORT: {
      return {...state, resumes: state.resumes.map((resume) => resume.id !== action.payload.id ? resume : {...resume, status: action.payload.status, result: action.payload.result})}
    }
    case ASSIGN_RESUME: {
      return {...state, resumes: state.resumes.filter((resume) => resume.id !== action.payload)}
    }
    case ARCHIVE_RESUME: {
      return {...state, resumes: state.resumes.filter((resume) => resume.hh_uid !== action.payload.id)}
    }
    case UPDATE_VACANCY: {
      return {...state, resumes: state.resumes.filter(({id: resume_id}) => action.payload.some(({id: payload_id}) => resume_id === payload_id))}
    }
    case TOGGLE_FAVOURITE: {
      return {...state, resumes: state.resumes.map((resume) => resume.hh_uid !== action.payload.id ? resume : {...resume, favourite: !action.payload.bool})}
    }
    case CLEAR_FIELDS: {
      return {...state, resumes: [], vacancy_info: null}
    }
    case GET_FILES: {
      return {...state, files: action.payload}
    }
    case UPLOAD_FILES: {
      return {...state, files: state.files.concat(action.payload)}
    }
    case GET_STATISTICS: {
      return {...state, vacancy_stats: action.payload}
    }
    case DELETE_VACANCY: {
      return {
        ...state,
        vacancies: state.vacancies.reduce((accum, curr)=>{
          if(curr.id != action.payload){
            return {...accum, curr}
          }
          return curr
        })
      }
    }
    default:
      return state
  }
}

export const showResume = (id) => async(dispatch) => {
  dispatch({type: SHOW_RESUME, payload: id})
}

export const hideResume = () => async(dispatch) => {
  dispatch({type: HIDE_RESUME})
}

export const recordCompanyInterview = (id, result) => async(dispatch) => {
  try {
    const res = await vacanciesAPI.recordCompanyInterview(id, result)
    dispatch({type: ASSIGN_RESUME, payload: res.data.id})
  } catch (error) {
    console.log(error.message)
  }
}

export const getCompanyInterviews = (id) => async(dispatch) => {
  try {
    const res = await vacanciesAPI.getCompanyInterviews(id)
    dispatch({type: GET_VACANCY_RESUMES, payload: res.data})
  } catch (error) {
    console.log(error.message)
  }
}

export const archiveResume = (id) => async(dispatch) => {
  try {
    const res = await vacanciesAPI.archiveResume(id)
    dispatch({type: ARCHIVE_RESUME, payload: res.data.hh_uid})
  } catch (error) {
    console.log(error.message)
  }
}

export const toggleArchive = (id, bool) => async(dispatch) => {
  try {
    const res = await vacanciesAPI.archiveResume(id)
    dispatch({type: ARCHIVE_RESUME, payload: id})
  } catch (error) {
    console.log(error.message)
  }
}

export const getArchivedResumes = () => async(dispatch) => {
  try {
    const res = await vacanciesAPI.getArchivedResumes()
    dispatch({type: GET_VACANCY_RESUMES, payload: res.data})
  } catch (error) {
    console.log(error.message)
  }
}

export const toggleFavourite = (id, bool) => async(dispatch) => {
  try {
    const res = await vacanciesAPI.toggleFavouriteResume(id)
    dispatch({type: TOGGLE_FAVOURITE, payload: {id, bool}})
  } catch (error) {
    console.log(error.message)
  }
}

export const getFavourites = () => async(dispatch) => {
  try {
    const res = await vacanciesAPI.getFavourites()
    console.log(res.data)
    dispatch({type: GET_VACANCY_RESUMES, payload: res.data})
  } catch (error) {
    console.log(error.message)
  }
}

export const editReport = (id, result) => async(dispatch) => {
  try {
    let res = await vacanciesAPI.updateReport(id, result)
    dispatch({type: UPDATE_REPORT, payload: res.data})
  } catch (error) {
    console.log(error.message)
  }
}

export const clearFields = () => async(dispatch) => {
  dispatch({type: CLEAR_FIELDS})
}

export const getVacancies = () => async(dispatch) => {
  try {
    const res = await vacanciesAPI.getVacancies()
    dispatch({type: GET_VACANCIES, payload: res.data})
  } catch (error) {
    console.log(error.message)
  }
}

export const getResumesByVacancy = (id) => async(dispatch) => {
  try {
    const res = await vacanciesAPI.getResumesByVacancy(id)
    dispatch({type: GET_VACANCY_RESUMES, payload: res.data})
  } catch (error) {
    console.log(error.message)
  }
}

export const setVacancyInfo = (vacancy) => async(dispatch) => {
  dispatch({type: SET_VACANCY_INFO, payload: vacancy})
}

export const getAssignedResumes = (id) => async(dispatch) => {
  try {
    const res = await vacanciesAPI.getAssignedResumes(id)
    dispatch({type: GET_VACANCY_RESUMES, payload: res.data})
  } catch (error) {
    console.log(error.message)
  }
}

export const updateResumes = (id) => async(dispatch) => {
  try {
    const res = await vacanciesAPI.getAssignedResumes(id)
    console.log(res.data)
    dispatch({type: UPDATE_VACANCY, payload: res.data})
  } catch (error) {
    console.log(error.message)
  }
}

export const assignResume = (vacancy, resume) => async(dispatch) => {
  try {
    const res = await vacanciesAPI.assignResumeToVacancy(vacancy, resume)
    dispatch({type: ASSIGN_RESUME, payload: resume})
  } catch (error) {
    console.log(error.message)
  }
}

export const searchResumes = (query) => async(dispatch) => {
  try {
    const res = await vacanciesAPI.searchVacancies(query)
    dispatch({type:GET_VACANCY_RESUMES, payload: res.data})
  } catch (error) {
    console.log(error.message)
  }
}

export const getFinishedCalls = () => async(dispatch) => {
  try {
    const res = await vacanciesAPI.getFinishedCalls()
    dispatch({type:GET_VACANCY_RESUMES, payload: res.data})
  } catch (error) {
    console.log(error.message)
  }
}

export const finishCall = (id, result) => async(dispatch) => {
  try {
    const user = localStorage.getItem("RadiusUserID")
    await vacanciesAPI.finishCall(id, result, user)
    dispatch({type: ASSIGN_RESUME, payload: id})
  } catch (error) {
    console.log(error.message)
  }
}

export const uploadRecord = (id, formData) => async(dispatch) => {
  try {
    await vacanciesAPI.uploadRecord(id, formData)
  } catch (error) {
    console.log(error.message)
  }
}

export const uploadDocuments = (id, formData) => async(dispatch) => {
  try {
    const res = await vacanciesAPI.uploadDocuments(id, formData)
    dispatch({type: UPLOAD_FILES, payload: res.data.files})
  } catch (error) {
    console.log(error.message)
  }
}

export const getDocuments = (id) => async(dispatch) => {
  try {
    const res = await vacanciesAPI.getDocuments(id)
    dispatch({type: GET_FILES, payload: res.data.files})
  } catch (error) {
    console.log(error.message)
  }
}

export const downloadDocument = (path) => async(dispatch) => {
  try {
    await vacanciesAPI.downloadDocument(path)
  } catch (error) {
    console.log(error.message)
  }
}

export const applyCandidate = (id) => async(dispatch) => {
  try {
    const res = await vacanciesAPI.inviteCandidate(id);
    dispatch({type: ASSIGN_RESUME, payload: id})
  } catch (error) {
    console.log(error.message)
  }
}

export const getInvitations = (id) => async(dispatch) => {
  try {
    const {data} = await vacanciesAPI.viewInvitedCandidates(id)
    dispatch({type: GET_VACANCY_RESUMES, payload: data})
  } catch (error) {
    console.log(error.message)
  }
} 

export const importFile = (formData) => async(dispatch) => {
  try {
    await vacanciesAPI.importFile(formData)
  } catch (error) {
    console.log(error.message)
  }
}

export const getStatistics = (id) => async(dispatch) => {
  try {
    const {data} = await vacanciesAPI.getStatistics(id)
    dispatch({type: GET_STATISTICS, payload: data})
  } catch (error) {
    console.log(error.message)
  }
}

export const moveResume = (id, stage, user) => async(dispatch) => {
  try {
    const res = await vacanciesAPI.moveResume(id, stage, user)
    return res.data.id
  } catch (error) {
    console.log(error.message)
  }
}

export const denyResume = (id, denied, user) => async(dispatch) => {
  try {
    const res = await vacanciesAPI.denyResume(id, denied, user)
    return res.data.id
  } catch (error) {
    console.log(error.message)
  }
}

export const delayResume = (id, user) => async(dispatch) => {
  try {
    const res = await vacanciesAPI.delayResume(id, user)
    return res.data.id
  } catch (error) {
    console.log(error.message)
  }
}

export const createNewVacancy = (data) => async(dispatch) => {
  try {
    const res = await vacanciesAPI.createNewVacancy(data)
    dispatch({type: NEW_VACANCY, payload: res.data})
    console.log(res)
  } catch (error) {
    console.log(error.message)
  }
}

export const uploadResumes = (data => async(dispatch) => {
  try {
    const res = await vacanciesAPI.uploadToVacancy(data)
    dispatch({type: GET_VACANCY_RESUMES, payload: res.data})
  } catch (error) {
    console.log(error.message)
  }
})

export const deleteVacancy = (id) => async(dispatch) => {
  try{
    const res = await vacanciesAPI.deleteVacancy(id)
    dispatch({type: DELETE_VACANCY, payload: res.id})
  }
  catch(error){
    console.log(error.message)
  }
}
import { savedResumesAPI } from "../api/api";

const GET_SAVED_RESUMES = "GET_SAVED_RESUMES"

export const hhResumeReducer = (state=[], action) => {
  switch (action.type) {
    case GET_SAVED_RESUMES:
      return action.payload;
    default:
      return state
  }
}

export const getSavedResumes = () => async(dispatch) => {
  try {
    let resumes = await savedResumesAPI.getSavedResumes()
    dispatch({type: GET_SAVED_RESUMES, payload: resumes.data})
  } catch (error) {
    console.log(error.message)
  }
}
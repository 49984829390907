import { resumesAPI } from "../api/api";
import { hhReqAPI } from "../api/hh_api";

const GET_IDs = "GET_IDs";
const GET_RESUMES = "GET_RESUMES";
const SEND_RESUMES = "SEND_RESUMES";
const initialState = {
  resumes: [],
};
export const resumesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_IDs:
      return action.payload;
    case GET_RESUMES:
      return { ...state, resumes: action.payload };
    case SEND_RESUMES:
      return { ...state, vacancies: [action.payload] };
    default:
      return state;
  }
};

export const getIDs = () => async (dispatch) => {
  try {
    let list = localStorage.getItem("hh_id_list");
    if (!list) {
      const res = await resumesAPI.getIDs();
      dispatch({ type: GET_IDs, payload: res.data });
      localStorage.setItem("hh_id_list", JSON.stringify(res.data));
    } else {
      dispatch({ type: GET_IDs, payload: JSON.parse(list) });
    }
  } catch (error) {
    console.log(error.message);
  }
};
export const getResumes = () => async (dispatch) => {
  try {
    const res = await resumesAPI.getResumes();
    dispatch({ type: GET_RESUMES, payload: res.data });
    console.log(res.data);
    localStorage.setItem("resumes", JSON.stringify(res.data));
  } catch (error) {
    console.log(error.message);
  }
};
export const sendResumes = (data) => async (dispatch) => {
  try {
    const res = await resumesAPI.sendResumes(data);
    // dispatch({ type: SEND_RESUMES, payload: res.data });
    console.log(res);
  } catch (error) {
    console.log(error.message);
    console.log(error);
  }
};

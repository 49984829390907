import { useEffect, useState } from "react"

export const ResumeInfo = ({resume}) => {

const [skills, setSkills] = useState([])
const years = Math.floor(resume.experience / 12)
const months = resume.experience % 12

useEffect(() => {
  if (resume.skills) {
    setSkills(resume.skills.split(','))
  }
}, [])

return (
  <div className="container px=0">
    <h5>{resume.full_name && `${resume.full_name}, `}{resume.title}</h5>
    {resume.area && (<h6>{resume.area}</h6>)}
    <div className = "row row-cols-3">
      <div className = "col-12">
        <h6>Контакты:</h6>
        <p className="m-0">Телефон:</p>
        {resume.phone ? (<a className="link-secondary m-0" href={`tel:+${resume.phone}`}>
          {resume.phone_formatted}
        </a>) : (<p className="m-0">Скрыт соискателем</p>)}
        <p className="m-0">E-Mail:</p>
        {resume.email ? (<a className="link-secondary m-0" href={`mailto:${resume.email}`}>
          {resume.email}
        </a>) : (<p className="m-0">Скрыт соискателем</p>)}
      </div>
      <div className = "col-12 mt-2">
        <p className = "m-0">
          {resume.age && resume.age !== 0 ? `${resume.age} 
          ${(resume.age % 10 === 1 && resume.age !== 11) ? 'год' :
          ((resume.age % 10 >= 2 && resume.age % 10 <= 4) && !(resume.age >= 12 && resume.age <= 14)) ? 'года' : 'лет'}` : "Возраст не указан"}
        </p>
        <p className="m-0">
          Опыт работы: {resume.experience ? (resume.experience != 0 ? (`${(years > 0) && `${years} ${(years % 10 === 1 && years !== 11) ? 'год' :
          ((years % 10 >= 2 && years % 10 <= 4) && !(years >= 12 && years <= 14)) ? 'года' : 'лет'}`} 
          ${months} ${(months === 1) ? 'месяц' : (months >= 2 && months <= 4) ? 'месяца' : 'месяцев'}`) : 'отсутствует') : 'не указан'}
        </p>
        <p className="mb-2">
          Зарплата: {resume.salary ? resume.salary : 'Не указана'}
        </p>
        { (resume.experience_info && resume.experience_info.length > 0) ? (<><h6 className="m-0">Последнее место работы:</h6>
        <p className="m-0">
          {resume.experience_info[0].position}, {resume.experience_info[0].company};
        </p>
        <p className="m-0">
          {new Date(resume.experience_info[0].start).toLocaleString('ru-RU', {month: 'long', year: 'numeric'})} - {resume.experience_info[0].end ? new Date(resume.experience_info[0].end).toLocaleString('ru-RU', {month: 'long', year: 'numeric'}) : `По настоящее время`}
        </p></>) : (<h6 className="m-0">Опыт работы отсутствует</h6>) }
      </div>
      {(skills && skills.length) > 0 && (
        <div className = "col-12 mt-2">
          <p className="m-0"> 
            Ключевые навыки:
          </p>
          <div className="container bg-secondary">
            {skills.map((skill) => 
              <span className="badge badge-dark">{skill}</span>
            )}
          </div>
        </div>
      )}
    </div>
  </div>
)
}
import { Link } from "react-router-dom"

export const Privacy = () => {
  return (
    <div className="container-fluid p-1" align="justify">
      <h3 className="text-dark">Политика конфиденциальности.</h3>
      <p className="text-secondary p-2">
      Настоящий документ «Политика конфиденциальности» (далее по тексту – «Политика») 
      представляет собой правила использования OOO Радиус (далее – «Администрация») 
      данных интернет-пользователей (далее - «Пользователь»), собираемых с использованием сайта <a className="link-secondary" href="https://hr-calls.radius-etl.ru">https://hr-calls.radius-etl.ru</a> (далее – «Сайт»).
      </p>
      <ol>
        <li>
          <h4>Обрабатываемые данные</h4>
        </li>
        <ol>
          <li>
            <p className="text-secondary">Администрация не осуществляет сбор персональных данных Пользователя с использованием Сайта.</p>
          </li>
          <li>
            <p className="text-secondary">Все данные, собираемые на Сайте, предоставляются и принимаются в обезличенной форме (далее – «Обезличенные данные»).</p>
          </li>
          <li>
            <p className="text-secondary">Обезличенные данные включают следующие сведения, которые не позволяют вас идентифицировать:</p>
          </li>
          <ol>
            <li>
              <p className="text-secondary">Данные, которые передаются в обезличенном виде в автоматическом режиме в зависимости от настроек используемого вами программного обеспечения.</p>
            </li>
          </ol>

          <li>
            <p className="text-secondary">Администрация вправе устанавливать требования к составу Обезличенных данных Пользователя, которые собираются использованием Сайта.</p>
          </li>
          <li>
            <p className="text-secondary">Пользователь осознает и принимает возможность использования на Сайте программного обеспечения третьих лиц, в результате чего такие лица могут получать и передавать указанные в п.1.3 данные в обезличенном виде.</p>
          </li>
          <li>
            <p className="text-secondary">Состав и условия сбора обезличенных данных с использованием программного обеспечения третьих лиц определяются непосредственно их правообладателями и могут включать:</p>
          </li>
          <ul>
            <li><p className="text-secondary">данные браузера (тип, версия, cookie);</p></li>
            <li><p className="text-secondary">данные устройства и место его положения;</p></li>
            <li><p className="text-secondary">данные операционной системы (тип, версия, разрешение экрана);</p></li>
            <li><p className="text-secondary">данные запроса (время, источник перехода, IP-адрес).</p></li>
          </ul>
          <li>
            <p className="text-secondary">Администрация не несет ответственность за порядок использования Обезличенных данных Пользователя третьими лицами.</p>
          </li>
        </ol>
        <li>
          <h4>Цели обработки данных</h4>
        </li>
        <ol>
          <li>
            <p className="text-secondary">Администрация использует данные в следующих целях:</p>
          </li>
          <ol>
            <li>
              <p className="text-secondary">Обработка поступающих запросов и связи с Пользователем</p>
            </li>
          </ol>
        </ol>
        <li>
          <h4>Требования к защите данных</h4>
        </li>
        <ol>
          <li>
            <p className="text-secondary">Администрация осуществляет хранение данных и обеспечивает их охрану от несанкционированного доступа и распространения в соответствии с внутренними правилами и регламентами.</p>
          </li>
          <li>
            <p className="text-secondary">В целях повышения качества работы Администрация вправе хранить лог-файлы о действиях, совершенных Пользователем в рамках использования Сайта в течение 1 (Одного) года.</p>
          </li>
        </ol>
        <li><h4>Передача данных</h4></li>
        <ol>
          <li>
            <p className="text-secondary">Администрация вправе передать данные третьим лицам в следующих случаях:</p>
          </li>
          <ol>
            <li>
              <p className="text-secondary">В связи с передачей Сайта во владение, пользование или собственность такого третьего лица;</p>
            </li>
            <li>
              <p className="text-secondary">По запросу суда или иного уполномоченного государственного органа в рамках установленной законодательством процедуры;</p>
            </li>
            <li>
              <p className="text-secondary">Для защиты прав и законных интересов Администрации в связи с допущенными Пользователем нарушениями.</p>
            </li>
          </ol>
        </ol>
        <li>
          <h4>Изменение Политики конфиденциальности</h4>
        </li>
        <ol>
          <li>
            <p className="text-secondary">Настоящая Политика может быть изменена или прекращена Администрацией в одностороннем порядке без предварительного уведомления Пользователя. Новая редакция Политики вступает в силу с момента ее размещения на Сайте, если иное не предусмотрено новой редакцией Политики.</p>
          </li>
          <li>
            <p className="text-secondary">Действующая редакция Политики находится на Сайте в сети Интернет по адресу. <Link to="/privacy" className="link-secondary">hr-calls.radius-etl.ru/privacy</Link></p>
          </li>
        </ol>
      </ol>
    </div>
  )
}
import { resultsAPI } from "../api/api";

const GET_RESULTS = "GET_RESULTS"
const PROCESS_CANDIDATE = "PROCESS_CANDIDATE"

export const resultsReducer = (state = [], action) => {
  switch(action.type) {
    case GET_RESULTS:
      return action.payload;
    case PROCESS_CANDIDATE:
      return state.filter((resume) => resume.id != action.payload)
    default:
      return state;
  }
}

export const getResults = (id) => async(dispatch) => {
  try {
    const {data} = await resultsAPI.getResults(id);
    dispatch({type: GET_RESULTS, payload: data})
  } catch (error) {
    console.log(error.message)
  }
}

export const applyCandidate = (id) => async(dispatch) => {
  try {
    const res = await resultsAPI.inviteCandidate(id);
    dispatch({type: PROCESS_CANDIDATE, payload: id})
  } catch (error) {
    console.log(error.message)
  }
}
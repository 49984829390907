import axios from "axios"
import qs from "qs"

//url запросов. 
//Локальный сервер: http://localhost:5000
// Черный сервер http://46.191.162.41:6500 
// https://uram.ddns.net:6500 
// https://hr.radius-lab.ru


//Облачный сервер Heroku: https://belle-croissant-54780.herokuapp.com
// export const url = 'http://localhost:5000'
export const url =  'https://hr.radius-lab.ru'
export const cloud_url = 'https://belle-croissant-54780.herokuapp.com'

//client_id приложения для HeadHunter
const client_id = 'K1LVH2LF4M87Q3I0D8SRRQA6JMBF5GL1TSJ25FCF9KAR1GCOM64M8GDG9JN47DVK'
export const hhAuthUrl = `https://hh.ru/oauth/authorize?response_type=code&client_id=${client_id}`

export const instance = axios.create({
  baseURL: url})
export const cloudinstance = axios.create({
  baseURL: cloud_url
})

export const authorizationAPI = {
  getLogin(){
    return instance.get('/authorization')
  }
}


export const campaignAPI = {
  getCallList(id) {
    return instance.get(`/call_list?id=${id}`);
  },
  getCampaigns() {
    return instance.get(`/campaigns`)
  },
  finishCall(data) {
    return instance.post(`/call_list`, {data})
  }
}

export const googleAPI = {
  loginGoogle(code) {
    return instance.get(`/google/login?code=${code}`)
    return axios.get(`https://uram.ddns.net/google/login?code=${code}`)
    },
  authorizeGoogle(tokens) {
    return instance.post(`/google/auth`, {tokens})
  },
  createGoogleContact(data) {
    return instance.post(`/google`, {data});
  } 
}

export const hhAuthAPI = {
  loginHH(code) {
    return instance.get(`/hh/login?code=${code}`)
    console.log(code);
  },
  authHH(id) {
    return instance.get(`/hh/auth?id=${id}`)
    console.log(id)
  },
  getUsers() {
    return instance.get(`/hh/users`)
  }
}

export const resultsAPI = {
  getResults(id) {
    return instance.get(`/results?id=${id}`)
  },
}

export const resumesAPI = {
  getResumes(){
    return instance.get(`/resumes`)
  },
  sendResumes(data){
    return instance.post(`/resumes/new`, data)
  }
}

export const savedResumesAPI = {
  getSavedResumes() {
    return instance.get(`/hh_resume`)
  }
}

export const vacanciesAPI = {
  getVacancies() {
    return instance.get(`/vacancies/full`)
  },
  createNewVacancy(data) {
    return instance.post(`/vacancies/new`, data)
  },
  uploadToVacancy(data) {
    return instance.post(`/vacancies/import`, data)
  },
  getResumesByVacancy(id){
    return instance.get(`/vacancies/resumes?id=${id}`)
  },
  getAssignedResumes(id){
    return instance.get(`/vacancies/assigned?id=${id}`)
  },
  assignResumeToVacancy(vacancy, resume){
    return instance.post(`/vacancies/assigned?vacancy=${vacancy}&resume=${resume}`)
  },
  searchVacancies(query) {
    return instance.get(`/vacancies/search?${query}`)
  },
  finishCall(id, result, user) {
    return instance.post(`/vacancies/assigned_call?id=${id}`, {result: result, user: user})
  },
  getFinishedCalls() {
    return instance.get(`/vacancies/report_list`)
  },
  uploadRecord(id, formData) {
    return instance.post(`/files/upload?id=${id}`, formData)
  },
  toggleFavouriteResume(id) {
    return instance.post(`/vacancies/favourite?id=${id}`)
  },
  getFavourites() {
    return instance.get(`/vacancies/favourite`)
  },
  archiveResume(id) {
    return instance.post(`/vacancies/archive?id=${id}`)
  },
  getArchivedResumes() {
    return instance.get(`/vacancies/archive`)
  },
  updateReport(id, result) {
    return instance.post(`/vacancies/report?id=${id}`, {result: result})
  },
  inviteCandidate(id) {
    return instance.post(`/vacancies/invited?id=${id}`)
  },
  viewInvitedCandidates(id) {
    return instance.get(`/vacancies/invited?id=${id}`)
  },
  uploadDocuments(id, formData) {
    return instance.post(`/files/documents?id=${id}`, formData)
  },
  downloadDocument(path) {
    return instance.get(`/files/download?path=${path}`)
  },
  getDocuments(id) {
    return instance.get(`/files/documents?id=${id}`)
  },
  getStatistics(id) {
    return instance.get(`/vacancies/statistics?id=${id}`)
  },
  getCompanyInterviews(id) {
    return instance.get(`/vacancies/company?id=${id}`)
  },
  recordCompanyInterview(id, result) {
    return instance.post(`/vacancies/company`, {id: id, result: result})
  },
  importFile(formData) {
    return instance.post(`/files/import`, formData)
  },
  moveResume(id, stage, user) {
    return instance.post(`/vacancies/stage`, {id, stage, user})
  },
  delayResume(id, user) {
    return instance.post(`/vacancies/delay`, {id, user})
  },
  denyResume(id, denied, user) {
    return instance.post(`/vacancies/denied`, {id, denied, user})
  },
  deleteVacancy(id){
    return instance.delete(`/vacancies/assigned?id=${id}`)
  }
}
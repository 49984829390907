import React, {useEffect, useState} from "react"
import { useDispatch, useSelector } from "react-redux"
import { getFavourites, clearFields } from "../../store/storedVacancyReducer"
import { FavouriteItem } from "./FavouriteItem"
import { Accordion, Offcanvas } from "react-bootstrap"

export const Favourites = () => {
  const dispatch = useDispatch()
  const resumes = useSelector((state) => state.storedVacancyReducer.resumes)

  useEffect(() => {
    dispatch(getFavourites())
  }, [])

  return (
    <div className="container-fluid px-0">
      {resumes.length > 0 && (
        <Accordion>
        {resumes.map((resume) => 
          <FavouriteItem
            key={resume.hh_uid}
            resume={resume}
          />
        )}
        </Accordion>
      )}
    </div>
  )
}
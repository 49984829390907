import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { getStatistics } from "../../store/storedVacancyReducer"
import { ResponsiveContainer, BarChart, Tooltip, XAxis, YAxis, Bar, Legend} from "recharts"
import { getUsers } from "../../store/headhunterReducer"

export const VacancyAnalytics = ({vac, dispatch}) => {
  const statistics = useSelector((state) => state.storedVacancyReducer.vacancy_stats)
  const users = useSelector((state) => state.headhunterReducer.userList)
  
  const [view, setView] = useState(0)

  useEffect(() => {
    dispatch(getUsers())
    dispatch(getStatistics(vac))
  }, [])

  const [data, setData] = useState({
    total: 0,
    unprocessed: 0,
    first_call: 0,
    new_first_call: 0,
    second_call: 0,
    new_second_call: 0,
    no_call: 0,
    new_no_call: 0,
    interview: 0,
    new_interview: 0,
    applied: 0,
    new_applied: 0,
    denied: 0,
    new_denied: 0,
  })

  const [chartData, setChartData] = useState([
    {
      name: "Все кандидаты",
      count: 0,
      hourly: 0
    },
    {
      name: "Неразобранные",
      count: 0,
      hourly: 0
    },
    {
      name: "Первичный звонок",
      count: 0,
      hourly: 0
    },
    {
      name: "Вторичный звонок",
      count: 0,
      hourly: 0
    },
    {
      name: "Собеседование",
      count: 0,
      hourly: 0
    },
    {
      name: "Выход на работу",
      count: 0,
      hourly: 0
    },
    {
      name: "Нет ответа",
      count: 0,
      hourly: 0
    },
    {
      name: "Отказ",
      count: 0,
      hourly: 0
    }
  ])

  const filterStats = (stats, status, stage) => {
    return {
      count: stats.filter((stat) => stat.status === status && (stat.interview_stage === stage || stage === undefined)).length > 0 ? 
      stats.filter((stat) => stat.status === status && (stat.interview_stage === stage || stage === undefined)).map(stat => stat.count).reduce((prev, curr) => parseInt(prev, 10) + parseInt(curr, 10), 0) : 0,
      hourly: stats.filter((stat) => stat.status === (status && stat.interview_stage === stage || stage === undefined)).length > 0 ? 
      stats.filter((stat) => stat.status === status && (stat.interview_stage === stage || stage === undefined)).map(stat => stat.hourly).reduce((prev, curr) => parseInt(prev, 10) + parseInt(curr, 10), 0) : 0,
    }
  }

  const getUserStats = (user) => {
    let userData = user ? statistics.filter((stat) => user.includes(stat.manager_id)) : statistics
    console.log(userData)
    setData({...data,
      first_call: filterStats(userData, 0, 1).count,
      new_first_call: filterStats(userData, 0, 1).hourly,
      second_call: filterStats(userData, 0, 2).count,
      new_second_call: filterStats(userData, 0, 2).hourly,
      interview: filterStats(userData, 0, 3).count,
      new_interview: filterStats(userData, 0, 3).hourly,
      applied: filterStats(userData, 0, 4).count,
      new_applied: filterStats(userData, 0, 4).hourly,
      no_call: filterStats(userData, 1).count,
      new_no_call: filterStats(userData, 1).hourly,
      denied: filterStats(userData, 2).count,
      new_denied: filterStats(userData, 2).hourly
    })
  }

  useEffect(() => {
    if (statistics.length > 0) {
    setData({...data,
      total: statistics.map(stat => stat.count).reduce((prev, curr) => parseInt(prev, 10) + parseInt(curr, 10), 0),
      unprocessed: statistics.filter((stat) => stat.status === null && stat.manager_id === null).length > 0 ? 
      statistics.filter((stat) => stat.status === null && stat.manager_id === null).map(stat => stat.count).reduce((prev, curr) => parseInt(prev, 10) + parseInt(curr, 10), 0) : 0,
      first_call: filterStats(statistics, 0, 1).count,
      new_first_call: filterStats(statistics, 0, 1).hourly,
      second_call: filterStats(statistics, 0, 2).count,
      new_second_call: filterStats(statistics, 0, 2).hourly,
      interview: filterStats(statistics, 0, 3).count,
      new_interview: filterStats(statistics, 0, 3).hourly,
      applied: filterStats(statistics, 0, 4).count,
      new_applied: filterStats(statistics, 0, 4).hourly,
      no_call: filterStats(statistics, 1).count,
      new_no_call: filterStats(statistics, 1).hourly,
      denied: filterStats(statistics, 2).count,
      new_denied: filterStats(statistics, 2).hourly
    })
    }
  }, [statistics])
  
  useEffect(() => {
    setChartData([
      {
        name: "Все кандидаты",
        count: data.total,
        hourly: 0
      },{
        name: "Неразобранные",
        count: data.unprocessed,
        hourly: 0
      },
      {
        name: "Первичный звонок",
        count: data.first_call - data.new_first_call,
        hourly: data.new_first_call
      },
      {
        name: "Вторичный звонок",
        count: data.second_call - data.new_second_call,
        hourly: data.new_second_call
      },
      {
        name: "Собеседование",
        count: data.interview - data.new_interview,
        hourly: data.new_interview
      },
      {
        name: "Выход на работу",
        count: data.applied - data.new_applied,
        hourly: data.new_applied
      },
      {
        name: "Нет ответа",
        count: data.no_call - data.new_no_call,
        hourly: data.new_no_call
      },
      {
        name: "Отказ",
        count: data.denied - data.new_denied,
        hourly: data.new_denied
      }
    ])
  }, [data])

  return (
    <div className="container-fluid px-0">
      <h4 className="p-3">Воронка кандидатов</h4>
      <div className="d-flex flex-row justify-content-start">
        <span type="button" className={`${view === 0 ? 'text-dark' : 'text-secondary'} mx-2`} onClick={() => setView(0)}><u>На графике</u></span>
        <span type="button" className={`${view === 1 ? 'text-dark' : 'text-secondary'} mx-2`} onClick={() => setView(1)}><u>В цифрах</u></span>
      </div>
      <div className="m-3">
        <select defaultValue="" className="form-select"
          onChange={(e) => getUserStats(e.target.value)}
        >
          <option value="">Все сотрудники</option>
          {users.map((user) => <option value={user.uid}>{user.last_name} {user.first_name}</option>)}
        </select>
      </div>
      {view === 0 ? (
        <ResponsiveContainer width="90%" height={500}>
        <BarChart  layout="vertical" height="90%" barCategoryGap={1} data={chartData} margin={{left: 100}}>
          <XAxis type="number" hide/>
          <YAxis type="category" dataKey="name"/>
          <Bar dataKey="count" stackId="a" fill="#8884d8"/>
          <Bar dataKey="hourly" stackId="a" fill="#82ca9d" label={{fill: 'black', fontSize: 20, position: 'insideLeft'}}/>
        </BarChart>
        </ResponsiveContainer>
      ) : (
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Этап подбора</th>
            <th scope="col">Кандидаты</th>
            <th scope="col">Динамика за час</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">Все кандидаты</th>
            <td>{data.total}</td>
            <td>-</td>
          </tr>
          <tr>
            <th scope="row">Неразобранные</th>
            <td>{data.unprocessed}</td>
            <td>-</td>
          </tr>
          <tr>
            <th scope="row">Первичный звонок</th>
            <td>{data.first_call}</td>
            <td>{parseInt(data.new_first_call, 10) > 0 ? (
              <b className="text-success">+{data.new_first_call}</b>
            ) : (data.new_first_call)}</td>
          </tr>
          <tr>
            <th scope="row">Вторичный звонок</th>
            <td>{data.second_call}</td>
            <td>{parseInt(data.new_second_call, 10) > 0 ? (
              <b className="text-success">+{data.new_second_call}</b>
            ) : (data.new_second_call)}</td>
          </tr>
          <tr>
            <th scope="row">Собеседование</th>
            <td>{data.interview}</td>
            <td>{parseInt(data.new_interview, 10) > 0 ? (
              <b className="text-success">+{data.new_interview}</b>
            ) : (data.new_interview)}</td>
          </tr>
          <tr>
            <th scope="row">Выход на работу</th>
            <td>{data.applied}</td>
            <td>{parseInt(data.new_applied, 10) > 0 ? (
              <b className="text-success">+{data.new_applied}</b>
            ) : (data.new_applied)}</td>
          </tr>
          <tr>
            <th scope="row">Нет ответа</th>
            <td>{data.no_call}</td>
            <td>{parseInt(data.new_no_call, 10) > 0 ? (
              <b className="text-success">+{data.new_no_call}</b>
            ) : (data.new_no_call)}</td>
          </tr>
          <tr>
            <th scope="row">Отказ</th>
            <td>{data.denied}</td>
            <td>{parseInt(data.new_denied, 10) > 0 ? (
              <b className="text-success">+{data.new_denied}</b>
            ) : (data.new_denied)}</td>
          </tr>
        </tbody>
      </table>
      )}
    </div>
  )
}
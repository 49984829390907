import { hhReqAPI } from "../api/hh_api";

const GET_VACANCY = "GET_VACANCY"

const initialState = {
  vacancy: null
}

export const hhVacancyReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_VACANCY:
      return {...state, vacancy: action.payload}
    default:
      return state
  }
}

// export const getVacancy = (id) => async(dispatch) => {
//   try {
//     let token = localStorage.getItem("hhAccessToken")
//     const res = await hhReqAPI.getVacancy(id, token)
//     console.log(res.data)
//     dispatch({type: GET_VACANCY, payload: res.data})
//   } catch (error) {
//     console.log(error.message)
//   }
// }

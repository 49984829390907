import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { createNewVacancy, getVacancies } from "../../store/storedVacancyReducer"
import { Link } from "react-router-dom"
import { Modal, Button } from "react-bootstrap"

export const VacancyList = () => {
  const dispatch = useDispatch()
  const vacancies = useSelector((state) => state.storedVacancyReducer.vacancies)
  const [newVac, setNewVac] = useState(false)
  const [title, setTitle] = useState("")
  const [desc, setDesc] = useState("")
  const [skills, setSkills] = useState([])

  const newVacOpen = () => setNewVac(true)
  const newVacClose = () => {
    setTitle("")
    setDesc("")
    setSkills("")
    setNewVac(false)
  }
  const newVacCreate = (title, desc, skills) => {
    const data = {
      title: title,
      desc: desc,
      skills: skills
    }
    dispatch(createNewVacancy(data))
    setTitle("")
    setDesc("")
    setSkills("")
    setNewVac(false)
  }

  useEffect(() => {
    dispatch(getVacancies())
  }, [])
  
  return (
    <div className="container-fluid px-0">
      <div className="list-group">
        <div className="list-group-item list-group-item-action" onClick={newVacOpen}>
          <div className="d-flex w-100 justify-content-between">
            <h4 className="my-1">+ Создать новую вакансию</h4>
          </div>
        </div>
      {vacancies.length > 0 && (
          vacancies.map((vacancy) => (
            <Link to={`/vacancy/${vacancy.id}`} className="list-group-item list-group-item-action">
              <div className="d-flex w-100 justify-content-between">
                <h5 className="mb-1">{vacancy.title}</h5>
                <small>Кандидатов в вакансии: {vacancy.count}</small>
              </div>
              <div className="d-flex justify-contend-end">
                <small>Ключевые навыки: {vacancy.skills}</small>
              </div>
            </Link>
          ))
        )}  
      </div>
      <Modal show={newVac} onHide={newVacClose} backdrop="static" centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Создать новую вакансию</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 className="mb-1">Наименование вакансии</h5>
          <div className="input-group mb-1">
            <input
              type = "text"
              className = "form-control"
              value = {title}
              onChange = {(e) => setTitle(e.target.value)}
            />
          </div>
          <h5 className="mb-1">Описание</h5>
          <div className="input-group mb-1">
            <textarea
              type = "text"
              className = "form-control"
              value = {desc}
              onChange = {(e) => setDesc(e.target.value)}
            />
          </div>
          <h5 className="mb-1">Ключевые навыки</h5>
          <div className="input-group mb-1">
            <input
              type = "text"
              className = "form-control"
              onChange = {(e) => setSkills(e.target.value.split(/[,. ]/).filter(Boolean))}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={newVacClose}>
            Отмена
          </Button>
          <Button variant="success" onClick={() => newVacCreate(title, desc, skills)} disabled={title == "" || desc == "" || skills.length == 0}>
            Создать
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
import { googleAPI } from "../api/api";

const GET_RESPONSE = "GET_RESPONSE";
const AUTH_GOOGLE = "AUTH_GOOGLE";
const SIGN_OUT_GOOGLE = "SIGN_OUT_GOOGLE"

const initialState = {
  isAuth: false,
  response: {},
  user:[]
}

export const googleReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_GOOGLE: 
    return {...state, isAuth: true, user: action.payload}
    case GET_RESPONSE:
      return {...state, response: action.payload};
    case SIGN_OUT_GOOGLE:
      return {...state, isAuth:false};
    default:
      return state;
  }
}

export const loginGoogle = (code) => async(dispatch) => {
  try {
    const res = await googleAPI.loginGoogle(code);  
    localStorage.setItem("googleAuthData", JSON.stringify(res.data))
    dispatch({type: AUTH_GOOGLE, payload: res.data})
    console.log(res)
  } catch (error) {
    console.log(error.message)
    console.log(error)
  }
}

export const createContact = (googleData) => async(dispatch) => {
  try {
    const {data} = await googleAPI.createGoogleContact(googleData)
    dispatch({type: GET_RESPONSE, payload: data})
  } catch (error) {
    console.log(error.message)
  }
}

export const authorizeGoogle = () => async(dispatch) => {
  try {
    const tokenstring = localStorage.getItem("googleAuthData")
    let tokens = JSON.parse(tokenstring)
    if (tokens.expiry_date > Date.now()) {
    const res = await googleAPI.authorizeGoogle(tokens);
    dispatch({type: AUTH_GOOGLE})
    }
  } catch (error) {
    console.log(error.message)
  }
}
export const signOutGoogle = () => async(dispatch) => {
  try{
    localStorage.removeItem("googleAuthData")
    dispatch({type:SIGN_OUT_GOOGLE})
  }
  catch(error){
    console.log(error.message)
  }
}
import React, {useEffect, useState} from "react"
import { useDispatch, useSelector } from "react-redux"
import { clearFields, getVacancies, searchResumes, setVacancyInfo } from "../store/storedVacancyReducer"
import { SavedResumeItem } from "./SavedResumeItem"
import { Accordion, Offcanvas, Pagination} from "react-bootstrap"


export const Search = () => {
  const dispatch = useDispatch()
  const vacancies = useSelector((state) => state.storedVacancyReducer.vacancies)
  const vacancy = useSelector((state) => state.storedVacancyReducer.vacancy_info)
  const resumes = useSelector((state) => state.storedVacancyReducer.resumes)

  useEffect(() => {
    dispatch(clearFields())
    dispatch(getVacancies())
  }, [])

  const showVacancy = (id) => {
    dispatch(setVacancyInfo(vacancies.find(vacancy => vacancy.id == id)))
  }

  const [offCanvasShow, setOffCanvasShow] = useState(false)
  const openOffCanvas = () => setOffCanvasShow(true)
  const closeOffCanvas = () => setOffCanvasShow(false)

  const [page, setPage] = useState(1);
  const resumesPerPage = 50;
  const resumesViewed = (page-1) * resumesPerPage;
  const totalPages = Math.ceil(resumes.length / resumesPerPage);

  const [queryData, setQueryData] = useState({
    skills: [],
    skills_logic: "any",
    city: [],
    text: [],
    title: [],
    title_logic: "any",
    text_logic: "any"
  })

  const searchHandler = () => {
    let query = ""
    if (queryData.skills.length > 0) {
      query = query.concat('skills=', queryData.skills.join().toLowerCase(), '&skills_logic=', queryData.skills_logic)
    }
    if (queryData.text.length > 0) {
      query = query.concat('&text=', queryData.text.join().toLowerCase(), '&text_logic=', queryData.text_logic)
    }
    if (queryData.city.length > 0) {
      query = query.concat('&city=', queryData.city.join().toLowerCase())
    }
    if (queryData.title.length > 0) {
      query = query.concat('&title=', queryData.title.join().toLowerCase(), '&title_logic=', queryData.title_logic)
    }
    dispatch(searchResumes(query))
  }

  return (
    <div className="container-fluid px-0">
      <select className="form-select" 
        onChange={(e) => showVacancy(e.target.value)}
      >
        <option defaultValue hidden>Вакансии</option>
        {vacancies.length > 0 && (
          vacancies.map((vacancy) => 
            <option key={vacancy.id} value={vacancy.id}>{vacancy.title}</option>
          )
        )}
      </select>
      {vacancy && (
        <div className="container-fluid py-2 px-5 m-0 bg-success">
          <h5>{vacancy.title} <button className="btn btn-secondary" onClick={openOffCanvas}>Подробнее</button></h5>
          <p className="m-0">Ключевые навыки:</p>
          <div className="btn-group-toggle" data-toggle="buttons">
            {vacancy.skills.split(',').map((skill) =>
              <span className="badge badge-dark">{skill}</span>
            )}
          </div>
        </div>
      )}
      <div className="row mx-0 px-0">
      <div className="col-12 col-lg-2 mt-3">
        <h4>Поиск</h4>
      <div className="form-group mb-1">
        <input type="text" className="form-control form-control-sm" placeholder="Из содержимого резюме" onChange={(e) => setQueryData({...queryData, text: (e.target.value.split(/[ ,.;]/).filter(Boolean))})}/>
          <select className="form-control form-control-sm" defaultValue="any" onChange={(e) => setQueryData({...queryData, text_logic: e.target.value})}>
            <option value="any">Любое слово</option>
            <option value="all">Все слова</option>
          </select>
      </div>
      <div className="form-group mb-1">
        <input type="text" className="form-control form-control-sm" placeholder="Название резюме" onChange={(e) => setQueryData({...queryData, title: (e.target.value.split(/[ ,.;]/).filter(Boolean))})}/>
          <select className="form-control form-control-sm" defaultValue="any" onChange={(e) => setQueryData({...queryData, title_logic: e.target.value})}>
            <option value="any">Любое слово</option>
            <option value="all">Все слова</option>
          </select>
      </div>
      <div className="form-group mb-1">
        <input type="text" className="form-control form-control-sm" placeholder="Ключевые навыки" onChange={(e) => setQueryData({...queryData, skills: (e.target.value.split(/[ ,.;]/).filter(Boolean))})}/>
          <select className="form-control form-control-sm" defaultValue="any" onChange={(e) => setQueryData({...queryData, skills_logic: e.target.value})}>
            <option value="any">Любое слово</option>
            <option value="all">Все слова</option>
          </select>
      </div>
      <div className="form-group mb-1">
        <input type="text" className="form-control form-control-sm" placeholder="Город проживания" onChange={(e) => setQueryData({...queryData, city: (e.target.value.split(/[ ,.;]/).filter(Boolean))})}/>      
      </div>
      <div className="d-grid">
      <button className="btn btn-secondary btn-sm btn-block" type="button" onClick={() => searchHandler()}>Поиск</button>
      </div>
      </div>
      <div className="col-12 col-lg-10 vh-100 overflow-auto">
      {resumes.length > 0 && (
        <Accordion>
        {resumes
          .slice(resumesViewed, resumesViewed + resumesPerPage)
          .map((resume) => 
          <SavedResumeItem
            key={resume.hh_uid}
            resume={resume}
            vacancies={vacancies}
            dispatch={dispatch}
          />
        )}
        </Accordion>
      )}
      <div className="mt-4 d-flex justify-content-center align-items-center">
        <Pagination>
          <Pagination.First disabled={page===1} onClick={() => setPage(1)}/>
          <Pagination.Prev disabled={page===1} onClick={() => setPage(page-1)}/>
          {page > 2 && (<Pagination.Item onClick={() => setPage(page - 2)}> {page - 2}</Pagination.Item>)}
          {page > 1 && (<Pagination.Item onClick={() => setPage(page - 1)}> {page - 1}</Pagination.Item>)}
          <Pagination.Item active>{page}</Pagination.Item>
          {page < (totalPages) && (<Pagination.Item onClick={() => setPage(page + 1)}> {page + 1}</Pagination.Item>)}
          {page < (totalPages-1) && (<Pagination.Item onClick={() => setPage(page + 2)}> {page + 2}</Pagination.Item>)}
          <Pagination.Next disabled={page>=totalPages} onClick={() => setPage(page+1)}/>
          <Pagination.Last disabled={page>=totalPages} onClick={() => setPage(totalPages)}/>
        </Pagination>
      </div>
      </div> 
      </div>
      {vacancy && (
      <Offcanvas placement='end' show={offCanvasShow} onHide={closeOffCanvas}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{vacancy.title}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <p className="p-2">Задачи:</p>
          <ol>
            {vacancy.task_description.split(';').map((task) =>
              <li>{task}</li>
            )}
          </ol>
        </Offcanvas.Body>
      </Offcanvas>
      )}
    </div>
  )
}
import { applyMiddleware, combineReducers, createStore } from "redux";
import thunkMiddleware from "redux-thunk";
import {callListReducer} from "./callListReducer";
import {campaignListReducer} from "./campaignListReducer"
import {googleReducer} from "./googleReducer"
import {headhunterReducer} from "./headhunterReducer"
import {resultsReducer} from "./resultsReducer"
import {resumesReducer} from "./resumesReducer"
import {hhResumeReducer} from "./hhResumeReducer"
import {hhVacancyReducer} from "./hhVacancyReducer"
import {storedVacancyReducer} from "./storedVacancyReducer"
import {loginReducer} from "./loginReducer"

let reducers = combineReducers({
  callListReducer,
  campaignListReducer,
  googleReducer,
  headhunterReducer,
  resultsReducer,
  resumesReducer,
  hhResumeReducer,
  hhVacancyReducer,
  storedVacancyReducer,
  loginReducer
})

export let store = createStore(reducers, applyMiddleware(thunkMiddleware))